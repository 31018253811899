<template>
    <li
        :class="background(toast.type)"
        class="z-50 w-fit rounded-lg border px-1 py-2 md:p-3 shadow-lg max-w-md"
        @mouseenter="mouseOnToast(true)"
        @mouseleave="mouseOnToast(false)"
    >
        <div class="grid grid-cols-[2rem_1fr_2rem] md:grid-cols-[3rem_1fr_3rem]">
            <div class="self-center justify-self-center">
                <component
                    :is="icon"
                    v-if="icon"
                    filled
                    class="fill-white"
                    width="30"
                    height="30"
                />
            </div>
            <div class="self-center ml-1 md:ml-2 mr-2">
                <span
                    v-if="toast.header"
                    class="font-extrabold"
                >
                    {{ i18n.te(toast.header) ? i18n.t(toast.header || '') : toast.header }}
                </span>
                <ul
                    v-if="!isString(toast.context) && !Array.isArray(toast.context)"
                >
                    <li
                        v-for="message in toast.context"
                        :key="message"
                        class="list-disc ml-6 md:ml-8 text-sm md:text-base leading-4"
                    >
                        {{ i18n.t(message) }}
                    </li>
                </ul>
                <span
                    v-else
                    class="block"
                >
                    {{ isString(toast.context)
                        ? i18n.t(toast.context)
                        : i18n.te(toast.context[0])
                            ? i18n.t(...toast.context)
                            : '' }}
                </span>
            </div>
            <div class="self-center justify-self-center">
                <IconXMark
                    class="fill-current cursor-pointer"
                    width="24"
                    height="24"
                    @click="dismiss(toast.toast_id)"
                />
            </div>
        </div>
    </li>
</template>

<script setup lang="ts">
import useState from '~/stores/State';
import IconCheck from '~/components/Icons/IconCheck.vue';
import IconQuestionMark from '~/components/Icons/IconQuestionMark.vue';
import IconWarning from '~/components/Icons/IconWarning.vue';
import IconXMark from '~/components/Icons/IconXMark.vue';
import { isString } from '~/helpers';
import type { Toast } from '~/lib/hooks/toast';

interface Props {
    toast: Toast;
}

const props = defineProps<Props>();

const state = useState();
const i18n = useI18n();

const dismiss = (id: number) => {
    state.toasts.delete(id);
};

const background = (type: Toast['type']) => {
    switch (type) {
    case 'success':
        return 'bg-wgm-green-300 border-wgm-green-600';
    case 'error':
        return 'bg-wgm-red-300 border-wgm-red-500';
    case 'warning':
        return 'bg-wgm-orange-300 border-wgm-orange-600';
    case 'info':
        return 'bg-wgm-blue-300 border-wgm-blue-600';
    default:
        return 'bg-white';
    }
};

const icon = computed(() => {
    switch (props.toast.type) {
    case 'error':
        return IconXMark;
    case 'warning':
        return IconWarning;
    case 'info':
        return IconQuestionMark;
    case 'success':
        return IconCheck;
    default:
        return null;
    }
});

const mouseOnToast = (status: boolean): void => {
    const $toast = state.toasts.get(props.toast.toast_id);

    if ($toast) {
        $toast.mouse_on_toast = status;
    }
};
</script>
