import axios from 'axios';
import useState from '~/stores/State';
import HttpClient from './HttpClient';
import RequestInterceptor from './RequestInterceptor';
import ResponseInterceptor from './ResponseInterceptor';
import useAuth from '~/stores/Auth';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { KeyValueObject } from '~/typings/interfaces';
import type {
    BaseHttpRequestConfig,
    FetchPayload,
    HttpMethod,
    HttpRequestInterceptor,
    HttpResponseInterceptor,
} from '~/typings/http';

class Http<C extends AxiosRequestConfig, E extends AxiosError> extends HttpClient<C, E> {
    public constructor() {
        super();

        axios.interceptors.response.use(response => response, async error => {
            const status = error.response?.status;
            const canRefresh = useAuth().canRefresh();

            if (status === 401 && canRefresh && error.config) {
                if (error.config.url === '/oauth/token') {
                    return Promise.reject(error);
                }

                return useAuth().refresh().then(async () => {
                    error.config.headers.Authorization = `Bearer ${useAuth().accessToken}`;

                    return axios.request(error.config);
                }).catch(async () => {
                    useAuth().invalidate(true, 'expired');

                    return Promise.reject(error);
                });
            }

            return Promise.reject(error);
        });
    }

    protected async performRequest<T>(
        method: HttpMethod,
        endpoint: string,
        data?: FetchPayload | KeyValueObject | FormData,
        config?: BaseHttpRequestConfig & Partial<C>,
    ): Promise<T> {
        const payload = ['get', 'delete'].includes(method) ? [{ ...config, data }] : [data, config];

        const auth = useAuth();

        if (auth.accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${auth.accessToken}`;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }

        useState().validationErrors = {};

        return axios[method](endpoint, ...payload)
            .then(response => {
                if (config?.unaltered) {
                    return response;
                }

                if (config?.paginate) {
                    return response.data;
                }

                return response.data?.data ?? response.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error) && error.response) {
                    const httpError = this.makeErrorObject(
                        error.response.status,
                        error.response.data,
                        error.config,
                        error,
                    );

                    throw httpError;
                }

                throw error;
            });
    }

    protected requestInterceptor(): HttpRequestInterceptor<C> {
        return new RequestInterceptor<C>();
    }

    protected responseInterceptor(): HttpResponseInterceptor<C, E> {
        return new ResponseInterceptor<C, E>();
    }
}

export default Http;
