<template>
    <footer class="py-5 md:py-8 border-t border-t-wgm-gray-300 text-wgm-gray-500 text-xs">
        <div class="max-w-5xl mx-auto grid grid-cols-3">
            <div class="px-4">
                <h5 class="font-bold my-2.5 text-[#999] text-sm">
                    {{ $t('general.footer.contact') }}
                </h5>
                <address class="mb-5 not-italic text-[#999]">
                    <strong>{{ $t('general.footer.tel') }}:</strong> 0570 858450<br>
                    <strong>{{ $t('general.footer.fax') }}:</strong> 0570 858451
                </address>
            </div>
            <div class="px-4 text-[#999]">
                <nav aria-role="Footer navigation company links">
                    <h5 class="font-bold my-2.5 text-[#999] text-sm">
                        {{ $t('general.footer.products') }}
                    </h5>
                    <ul class="mb-[0.625rem]">
                        <li>
                            <a
                                href="http://zuurstofinfo.nl"
                                class="text-wgm-gray-600 hover:text-wgm-gray-800 block py-0.5"
                                target="_blank"
                            >{{ $t('general.footer.products_range.oxygen_therapy') }}</a>
                        </li>
                        <li>
                            <a
                                href="http://cpapinfo.nl"
                                class="text-wgm-gray-600 hover:text-wgm-gray-800 block py-0.5"
                                target="_blank"
                            >{{ $t('general.footer.products_range.pap_therapy') }}</a>
                        </li>
                        <li>
                            <a
                                href="http://vernevelaarinfo.nl"
                                class="text-wgm-gray-600 hover:text-wgm-gray-800 block py-0.5"
                                target="_blank"
                            >{{ $t('general.footer.products_range.nebulizer_therapy') }}</a>
                        </li>
                        <li>
                            <a
                                href="http://pepinformatie.nl"
                                class="text-wgm-gray-600 hover:text-wgm-gray-800 block py-0.5"
                                target="_blank"
                            >{{ $t('general.footer.products_range.pep_therapy') }}</a>
                        </li>
                        <li>
                            <a
                                href="http://oxyrunner.com"
                                class="text-wgm-gray-600 hover:text-wgm-gray-800 block py-0.5"
                                target="_blank"
                            >{{ $t('general.footer.products_range.oxyrunner') }}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="px-4 text-[#999]">
                <h5 class="font-bold my-2.5 text-[#999] text-sm">
                    Westfalen Medical B.V.
                </h5>
                <ul class="mb-[0.625rem]">
                    <li>{{ $t('general.footer.chamber_of_commerce') }} nr: 52789055</li>
                    <li>{{ $t('general.footer.vat_number') }} nr: NL850598229B01</li>
                </ul>
                <ul class="mb-[0.625rem]">
                    <li>© 2022 Westfalen Medical</li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { sleep } from '~/helpers';
import TawkChat from '~/lib/chat/TawkChat';

const chatIsOnline = ref(false);
const tawkApiIsLoaded = ref(false);

const setTawkApiStatus = (): void => {
    sleep(1000) // Hopefully ensure the script tag is loaded and the Tawk API has become available
        .then(() => {
            let attempts = 0;

            while (!tawkApiIsLoaded.value && attempts < 100) {
                const tawkApi = TawkChat.api();

                if (tawkApi !== null) {
                    chatIsOnline.value = tawkApi.getStatus?.() === 'online';

                    // eslint-disable-next-line no-loop-func
                    tawkApi.onStatusChange = status => {
                        chatIsOnline.value = status === 'online';

                        if (!chatIsOnline.value) {
                            tawkApi.hideWidget();

                            return;
                        }

                        tawkApi.showWidget();
                    };

                    tawkApiIsLoaded.value = true;
                }

                attempts += 1;
            }
        });
};

onMounted(() => {
    setTawkApiStatus();
});
</script>
