/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable id-length */
import elevenTest from './eleven-test';
import type { ValidationRuleFunction } from '..';

const socialSecurityNumberRules: Record<string, ValidationRuleFunction> = {
    eleven_test: (value: unknown) => elevenTest(String(value)),
};

export default socialSecurityNumberRules;
