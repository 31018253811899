<template>
    <div
        tabindex="0"
        class="outline-none"
        @keydown.prevent.enter="$emit('open')"
    >
        <div
            class="cursor-pointer flex justify-between items-center outline-none"
            :class="headerClass"
            @click="onToggle"
        >
            <slot name="header"></slot>
            <IconSimpleArrowUp
                v-if="showArrow"
                class="fill-current transition-transform"
                :class="{'-rotate-90': !open, '-rotate-180': open}"
                width="8"
                height="8"
            />
        </div>
        <slot name="limbo"></slot>
        <div
            class="overflow-hidden transition-[max-height,opacity] duration-500 ease-in-out"
            :class="{'max-h-[0] opacity-0': !open, 'max-h-[200vh] opacity-100': open, ...contentClass}"
            :aria-expanded="open"
        >
            <div :class="{'invisible': !open}">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

interface Props {
    defaultOpen?: boolean;
    showArrow?: boolean;
    openCondition?: boolean;
    blockToggling?: boolean;
    headerClass?: HTMLAttributes['class'];
    contentClass?: HTMLAttributes['class'];
}

interface Events {
    (event: 'toggled', value: boolean): void;
    (event: 'open'): void;
    (event: 'close'): void;
    (event: 'on-header-click', value: Event): void;
}

const props = withDefaults(defineProps<Props>(), {
    defaultOpen: false,
    showArrow: true,
    openCondition: undefined,
    blockToggling: undefined,
    headerClass: undefined,
    contentClass: undefined,
});

const emit = defineEmits<Events>();
const open = ref(props.defaultOpen);

const onToggle = (event: Event): void => {
    if (props.blockToggling === true) {
        return;
    }

    open.value = !open.value;
    emit('on-header-click', event);
};

watch(() => props.openCondition, () => {
    if (props.openCondition !== undefined) {
        open.value = props.openCondition;
    }
});

watch(open, () => {
    emit('toggled', open.value);
});
</script>
