<template>
    <div class="text-center">
        <div class="inline-block relative w-10 h-10 z-9999999 mx-auto -translate-x-4">
            <div
                class="block absolute w-12 h-12 m-2 border-4 rounded-full animate-loader [animation-delay:-450ms]"
                :class="borderClass"
            ></div>
            <div
                class="block absolute w-12 h-12 m-2 border-4 rounded-full animate-loader [animation-delay:-300ms]"
                :class="borderClass"
            ></div>
            <div
                class="block absolute w-12 h-12 m-2 border-4 rounded-full animate-loader [animation-delay:-150ms]"
                :class="borderClass"
            ></div>
            <div
                class="block absolute w-12 h-12 m-2 border-4 rounded-full animate-loader"
                :class="borderClass"
            ></div>
        </div>
        <div
            v-if="message"
            class="mt-5"
        >
            <span class="text-wgm-gray-400">{{ $t(message) }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { i18nKey } from '~/typings/types';

interface Props {
    message?: i18nKey;
    color?: 'blue' | 'white';
}

const props = withDefaults(defineProps<Props>(), {
    color: 'blue',
    message: undefined,
});

const borderClass = computed(() => {
    return props.color === 'blue'
        ? 'border-[#002F63_transparent_transparent_transparent]'
        : 'border-[#FFFFFF_transparent_transparent_transparent]';
});
</script>

