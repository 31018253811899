import useAuth from '~/stores/Auth';
import useMiddleware from '~/lib/hooks/middleware';
import { UserStatus } from '~/typings/enums';
import { normalizeRoute } from '~/lib/router/RouterPipeline';

const ensureUserIsApproved = useMiddleware(async (to, from, next) => {
    const auth = useAuth();

    if (!auth.check()) {
        return next(to, from);
    }

    if (auth.user?.user_status === UserStatus.Rejected) {
        return normalizeRoute('unauthorized');
    }

    return auth.isApproved()
        ? next(to, from)
        : normalizeRoute('status');
});

export default ensureUserIsApproved;
