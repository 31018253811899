export default function passesElevenTest(value: string): boolean {
    if (value.length !== 9) {
        return false;
    }

    let total = 0;
    let index = 9;

    for (let jndex = 0; jndex < 8; jndex += 1) {
        const char = value[jndex];
        total += parseInt(char, 10) * index;
        index -= 1;
    }

    const rest = parseInt(value.charAt(8), 10);

    return ((total % 11) === rest);
}
