import type { ChannelAuthorizationCallback } from 'pusher-js';
import type { NotificationTemplate } from '~/stores/Notification';
import type { ClassName, NotificationClassName } from '~/typings/enums';
import type { Uuid } from '~/typings/types';

export interface RealtimeEvents {
    subscribe: () => void;
    unsubscribe: () => void;
}

export enum BroadcastedEvent {
    UpdatedModelEvent = 'UpdatedModelEvent',
    DeletedModelEvent = 'DeletedModelEvent',
    NotificationEvent = 'NotificationEvent',
    LogOutEvent = 'LogOutEvent',
    BroadcastNotificationCreatedEvent = 'BroadcastNotificationCreatedEvent',
}

export interface BroadcastedModelEventValue {
    className: ClassName;
    uuid: Uuid;
}

export interface NotificationEventValue {
    uuid: Uuid;
    user_uuid: string;
    template: NotificationTemplate;
    created_at: string;
}

export interface BroadcastNotificationCreatedEventValue {
    uuid: Uuid;
    type: NotificationClassName;
    template: NotificationTemplate;
    created_at: string;
}

export type LogOutEventValue = {
    uuid: Uuid;
}

export type EventHandlers = Record<BroadcastedEvent, (event: any) => void>;

export type ChannelAuthorizationData = Parameters<ChannelAuthorizationCallback>['1'];
