import createModel, { defineModel } from '~/lib/model';
import type { ClassName } from '~/typings/enums';
import type { Model, ModelDefinition } from '~/typings/model';
import type { Uuid } from '~/typings/types';

export interface Contact extends Model {
    model_type: ClassName;
    model_uuid: Uuid;
    company: string | null;
    name: string | null;
    email: string | null;
    phonenumber_uuids: Uuid[];
    comment: string | null;
}

const contactModel = createModel('contacts', defineModel<Contact>(), {});

const useContact = (): ModelDefinition<typeof contactModel> => contactModel();

export default useContact;
