import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './assets/style.css';
import { createApp } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Vue3Tour from 'vue3-tour';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import { createRouter, createWebHistory } from 'vue-router';
import Tooltip from 'primevue/tooltip';
import { z } from 'zod';
import { VueQueryPlugin } from '@tanstack/vue-query';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import { IonicVue } from '@ionic/vue';
import { loadLocale } from '~/lib/datetime/locales';
import messages from '~/lang';
import numberFormats from '~/lang/numberFormats';
import App from '~/App.vue';
import config from '~/config';
import routes from '~/routes';
import pipes from '~/middleware';
import withPipeline from '~/lib/router/RouterPipeline';
import useAuth from './stores/Auth';
import TawkChat from './lib/chat/TawkChat';
import { handleError, isProduction, userRoles } from './helpers';
import validation from './validation';
import { hasActiveToasts } from './lib/hooks/toast';
import useHttp from './lib/http';
import type { App as Application } from 'vue';
import './service-worker/register';
import 'vue3-tour/dist/vue3-tour.css';

function assertEnvironmentIsValid(): void {
    const expectedEnvironment = z
        .object({
            BASE_URL: z.string(),
            DEV: z.boolean(),
            MODE: z.string(),
            PROD: z.boolean(),
            SSR: z.boolean().optional(),
            VITE_PUSHER_APP_CLUSTER: z.string(),
            VITE_PUSHER_APP_KEY: z.string(),
            VITE_SERVER_ENVIRONMENT: z.enum(['local', 'test', 'release', 'production']),
            VITE_WGM_BASE_API: z.string().url(),
            VITE_GOOGLE_MAPS_API_KEY: z.string(),
            VITE_USER_NODE_ENV: z.string().optional(),
            VITE_APP_DEV_SERVER_PORT: z.string().optional(),
            VITE_APP_DEV_WS_HMR: z.string().optional(),
            VITE_IS_TOUR_ENABLED: z.string().optional(),
            VITE_PASSPORT_CLIENT_ID: z.string(),
            VITE_PASSPORT_CLIENT_SECRET: z.string(),
        })
        .strict();

    try {
        expectedEnvironment.parse(import.meta.env);

        if (import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production') {
            console.log(`[Env]: ${import.meta.env.VITE_SERVER_ENVIRONMENT}`);
        }
    } catch (error) {
        if (!isProduction()) {
            console.log('[Main]: Invalid environment:', import.meta.env);
        } else {
            console.log('[Main]: Invalid environment.');
        }

        document.getElementById('loader')?.remove();
        document.body.innerHTML += '<p style="padding:1rem;">Invalid environment.</p>';

        throw error;
    }
}

function installPinia(app: Application): void {
    const pinia = createPinia();

    pinia.use(piniaPluginPersistedstate);
    app.use(pinia);
}

function installI18n(app: Application): void {
    const i18n = createI18n({
        legacy: false,
        locale: config.locale,
        fallbackLocale: config.fallbackLocale,
        globalInjection: true,
        messages,
        warnHtmlMessage: false,
        missingWarn: false,
        fallbackWarn: false,
        numberFormats,
    });

    app.use(i18n);
    app.use(validation);
}

function installDirectives(app: Application): void {
    app.directive('tooltip', Tooltip);
}

function installRouter(app: Application): void {
    const router = withPipeline(
        createRouter({
            history: createWebHistory('/'),
            routes,
        }),
        pipes,
    );

    app.use(router);
}

function installTawkChat(): void {
    const auth = useAuth();

    if (auth.hasAnyRole(userRoles())) {
        return;
    }

    TawkChat.install();
}

function installErrorHandler(app: Application): void {
    app.config.errorHandler = (error, _component, info) => {
        let developerMessage = '';

        if (useHttp().isHttpError(error)) {
            const status = error.response.status;

            if (status < 500 && !import.meta.env.DEV) {
                if (import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production') {
                    console.error(error);
                }

                return;
            }

            developerMessage = error.response.original.message;

            handleError(error, developerMessage);

            return;
        }

        if (error instanceof Error) {
            developerMessage = `[${info}] ${error.message}`;
        } else {
            developerMessage = info;
        }

        handleError(error, developerMessage);

        // TODO: log error to Sentry?
    };

    window.addEventListener('unhandledrejection', event => {
        if (import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production' && !hasActiveToasts()) {
            const error = event.reason;
            handleError(error, (error instanceof Error) ? `${error.message}` : 'Unhandled promise rejection');
        }

        if (import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production') {
            console.error(event);
        }

        return true;
    });
}

function installIonic(app: Application): void {
    app.use(IonicVue);
}

function installPrimeVue(app: Application) {
    app.use(PrimeVue);
    app.use(DialogService);
}

async function main() {
    assertEnvironmentIsValid();
    await loadLocale(config.locale);
    const app = createApp(App);
    installDirectives(app);
    // app.use(PrimeVue);
    installPinia(app);
    installI18n(app);
    installPrimeVue(app);
    await useAuth().authenticate(); // Should happen before router installation
    installRouter(app);
    installTawkChat();
    installErrorHandler(app);
    app.use(VueQueryPlugin);
    app.use(Vue3Tour);
    installIonic(app);
    document.getElementById('loader')?.remove();
    app.mount('#app');
}

main();
