import dayjs from 'dayjs';
import useState from '~/stores/State';
import BaseDatetime from './BaseDatetime';
import type { Dayjs } from 'dayjs';
import type { DatetimeFormat } from '~/typings/types';

class Datetime extends BaseDatetime {
    private $date: Dayjs;

    public constructor(date: string) {
        super();

        this.$date = dayjs(date);
    }

    public static parse(date: string): Datetime {
        return new this(date);
    }

    /**
     * hh:mm --- 8:02 PM \
     * hh:mm:ss --- 8:02:18 PM \
     * dddd --- Sunday-Saturday \
     * dddd (hh:mm) --- Thursday (8:02 PM)
     * MM/DD/YYYY --- 08/16/2018 \
     * MMMM D, YYYY --- August 16, 2018 \
     * MMMM D, YYYY hh:mm --- August 16, 2018 8:02 PM \
     * dddd, MMMM D, YYYY hh:mm --- Thursday, August 16, 2018 8:02 PM \
     * M/D/YYYY --- 8/16/2018 \
     * MMM D, YYYY --- Aug 16, 2018 \
     * MMM D, YYYY hh:mm --- Aug 16, 2018 8:02 PM \
     * ddd, MMM D, YYYY hh:mm ---  Thu, Aug 16, 2018 8:02 PM \
     * iso
     * DD-MM-YYYY HH:mm --- 16-08-2018 08:02
     * @see https://day.js.org/docs/en/display/format
     */
    public static format(date: string, format: DatetimeFormat, reactive = true): string {
        return Datetime.parse(date).format(format, reactive);
    }

    public static now(): Datetime {
        return new Datetime(dayjs().toString());
    }

    public static isPast(date: string): boolean {
        return dayjs(date).isBefore(dayjs());
    }

    public static isFuture(date: string): boolean {
        return dayjs(date).isAfter(dayjs());
    }

    public static isBefore(dateX: string, dateY: string): boolean {
        return dayjs(dateX).isBefore(dayjs(dateY));
    }

    public static isAfter(dateX: string, dateY: string): boolean {
        return dayjs(dateX).isAfter(dayjs(dateY));
    }

    public static isBetween(date: string, dateX: string, dateY: string): boolean {
        return !Datetime.isBefore(date, dateX) && !Datetime.isAfter(date, dateY);
    }

    public static isToday(date: string): boolean {
        return dayjs(date).isSame(dayjs(), 'date');
    }

    public static isSame(dateX: string, dateY: string, type: 'day' | 'week' | 'year'): boolean {
        return dayjs(dateX).isSame(dateY, type);
    }

    /**
     * hh:mm --- 8:02 PM \
     * hh:mm:ss --- 8:02:18 PM \
     * dddd --- Sunday-Saturday \
     * dddd (hh:mm) --- Thursday (8:02 PM)
     * MM/DD/YYYY --- 08/16/2018 \
     * MMMM D, YYYY --- August 16, 2018 \
     * MMMM D, YYYY hh:mm --- August 16, 2018 8:02 PM \
     * dddd, MMMM D, YYYY hh:mm --- Thursday, August 16, 2018 8:02 PM \
     * M/D/YYYY --- 8/16/2018 \
     * MMM D, YYYY --- Aug 16, 2018 \
     * MMM D, YYYY hh:mm --- Aug 16, 2018 8:02 PM \
     * ddd, MMM D, YYYY hh:mm ---  Thu, Aug 16, 2018 8:02 PM \
     * iso
     * @see https://day.js.org/docs/en/display/format
     */
    public format(format: DatetimeFormat, reactive = true): string {
        const formatDate = (): string => {
            switch (format) {
                case 'hh:mm':
                    return this.$date.format('LT'); // 8:02 PM
                case 'hh:mm:ss':
                    return this.$date.format('LTS'); // 8:02:18 PM
                case 'dddd':
                    /* eslint-disable no-case-declarations */
                    const date = this.$date.format('LLLL');

                    return date.includes(', ') // Thursday
                        ? date.split(',')[0]
                        : date.split(' ')[0];
                case 'dddd (hh:mm)':
                    const day = this.format('dddd');

                    return `${day} (${this.$date.format('LT')})`; // Thursday (8:02 PM)
                case 'MM/DD/YYYY':
                    return this.$date.format('L'); // 08/16/2018
                case 'YYYY-MM-DD':
                    return this.$date.format('YYYY-MM-DD'); // 2018/01/31
                case 'DD-MM-YYYY HH:mm':
                    return this.$date.format('DD-MM-YYYY HH:mm'); // 2018/01/31
                case 'MMMM D, YYYY':
                    return this.$date.format('LL'); // August 16, 2018
                case 'MMMM D, YYYY hh:mm':
                    return this.$date.format('LLL'); // August 16, 2018 8:02 PM
                case 'dddd, MMMM D, YYYY hh:mm':
                    return this.$date.format('LLLL'); // Thursday, August 16, 2018 8:02 PM
                case 'M/D/YYYY':
                    return this.$date.format('l'); // 8/16/2018
                case 'MMM D, YYYY':
                    return this.$date.format('ll'); // Aug 16, 2018
                case 'MMM D, YYYY hh:mm':
                    return this.$date.format('lll'); // Aug 16, 2018 8:02 PM
                case 'ddd, MMM D, YYYY hh:mm':
                    return this.$date.format('llll'); // Thu, Aug 16, 2018 8:02 PM
                case 'iso':
                    return this.$date.toISOString();
                default:
                    return this.$date.format(format);
            }
        };

        if (!reactive) {
            return formatDate();
        }

        const initialDate = formatDate();
        const reactiveDate = ref(initialDate);
        const state = useState();

        watch(() => state.locale, () => {
            this.$date = dayjs(initialDate); // Ensure $date has updated locale
            reactiveDate.value = formatDate();
        });

        return reactiveDate.value;
    }

    public unix(): number {
        return this.$date.unix();
    }

    public addHour(hours = 1): this {
        this.$date = this.$date.add(hours, 'hours');

        return this;
    }

    public addDay(days = 1): this {
        this.$date = this.$date.add(days, 'day');

        return this;
    }

    public addWeek(weeks = 1): this {
        this.$date = this.$date.add(weeks, 'week');

        return this;
    }

    public addMonth(months = 1): this {
        this.$date = this.$date.add(months, 'month');

        return this;
    }

    public addYear(years = 1): this {
        this.$date = this.$date.add(years, 'year');

        return this;
    }

    public subHour(hours = 1): this {
        this.$date = this.$date.subtract(hours, 'hours');

        return this;
    }

    public subDay(days = 1): this {
        this.$date = this.$date.subtract(days, 'day');

        return this;
    }

    public subWeek(weeks = 1): this {
        this.$date = this.$date.subtract(weeks, 'week');

        return this;
    }

    public subMonth(months = 1): this {
        this.$date = this.$date.subtract(months, 'month');

        return this;
    }

    public subYear(years = 1): this {
        this.$date = this.$date.subtract(years, 'year');

        return this;
    }

    public setMillisecond(millisecond: number): this {
        if (millisecond >= 0 && millisecond <= 999) {
            this.$date = this.$date.millisecond(millisecond);
        }

        return this;
    }

    public setSecond(second: number): this {
        if (second >= 0 && second <= 59) {
            this.$date = this.$date.second(second);
        }

        return this;
    }

    public setMinute(minute: number): this {
        if (minute >= 0 && minute <= 59) {
            this.$date = this.$date.minute(minute);
        }

        return this;
    }

    public setHour(hour: number): this {
        if (hour >= 0 && hour <= 23) {
            this.$date = this.$date.hour(hour);
        }

        return this;
    }

    public startOfDay(): this {
        this.$date = this.$date.startOf('day');

        return this;
    }

    public endOfDay(): this {
        this.$date = this.$date.endOf('day');

        return this;
    }

    public toString(): string {
        return this.$date.toString();
    }

    public toHumanReadable(reactive = true): string {
        if (!reactive) {
            return this.$date.fromNow();
        }

        const initialDate = this.$date.toString();
        const readable = ref(this.$date.fromNow());
        const state = useState();

        const update = () => {
            this.$date = dayjs(initialDate); // Ensure $date has updated locale
            readable.value = this.$date.fromNow();
        };

        watch(() => state.locale, update);
        setTimeout(update, 60_000);

        return readable.value;
    }
}

export function useDatetime(): typeof Datetime;
export function useDatetime(date: string): Datetime;
export function useDatetime(date?: string): Datetime | typeof Datetime {
    return date ? Datetime.parse(date) : Datetime;
}
