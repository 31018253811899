<template>
    <li
        class="px-2 overflow-x-hidden"
    >
        <div
            class="p-2 hover:bg-wgm-gray-100 flex gap-2 cursor-pointer"
            :class="{'border-t border-t-wgm-gray-200 hover:border-t-transparent': index > 0}"
        >
            <div>
                <span class="rounded-full bg-wgm-blue-800 h-2 w-2 block mt-1.5"></span>
            </div>
            <div class="overflow-x-hidden w-full">
                <h4 class="font-semibold truncate">
                    {{ i18n.te(title[0]) ? $t(...title) : title[0] }}
                </h4>
                <div class="truncate">
                    {{ i18n.te(body[0]) ? $t(...body) : body[0] }}
                </div>
                <div class="flex justify-end text-wgm-gray-400 text-xs items-center">
                    <IconClock class="scale-50 text-black fill-current" />
                    <span>{{ useDatetime(notification.created_at).toHumanReadable() }}</span>
                </div>
            </div>
        </div>
    </li>
</template>

<script setup lang="ts">
import { isString } from '~/helpers';
import { useDatetime } from '~/lib/datetime';
import type { Notification } from '~/stores/Notification';
import type { i18nKey } from '~/typings/types';

interface Props {
    notification: Notification;
    index: number;
}

const props = defineProps<Props>();
const i18n = useI18n();

const title = computed<[i18nKey, Record<string, string>]>(() => {
    if (!props.notification) {
        return ['', {}];
    }

    return isString(props.notification.template.title)
        ? [props.notification.template.title, {}]
        : props.notification.template.title;
});

const body = computed<[i18nKey, Record<string, string>]>(() => {
    if (!props.notification) {
        return ['', {}];
    }

    return isString(props.notification.template.body)
        ? [props.notification.template.body, {}]
        : props.notification.template.body;
});
</script>
