const apiBase = import.meta.env.MODE === 'test'
    ? '/'
    : import.meta.env.VITE_WGM_BASE_API ?? 'https://wgm-api.pro.test';

const config = {
    api: apiBase,
    fallbackLocale: 'nl',
    locale: localStorage.getItem('locale') ?? 'nl',
} as const;

export default config;
