import { Permission } from '~/typings/enums';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'stock',
        name: 'module.tracking-system.stock',
        component: async () => import('../views/Stock/Overview.vue'),
        meta: {
            title: 'Voorraad',
            permission: [
                Permission.Module_TrackingSystem_Stock_View,
            ],
        },
    },
    {
        path: 'stock/:materialId',
        name: 'module.tracking-system.stock.show',
        component: async () => import('../views/Stock/Detail.vue'),
        meta: {
            title: 'Voorraad',
            permission: [
                Permission.Module_TrackingSystem_Stock_View,
            ],
        },
    },
];

export default routes;
