import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import type locale from 'dayjs/locale/*';

/* eslint-disable unicorn/prevent-abbreviations */
const localesOptions = {
    de: async (): Promise<locale.Locale> => import('dayjs/locale/de'),
    en: async (): Promise<locale.Locale> => import('dayjs/locale/en'),
    nl: async (): Promise<locale.Locale> => import('dayjs/locale/nl'),
} as const;
/* eslint-enable unicorn/prevent-abbreviations */

let extensionLoaded = false;

export const loadLocale = async (locale: string): Promise<void> => {
    if (Object.prototype.hasOwnProperty.call(localesOptions, locale)) {
        return localesOptions[locale as keyof typeof localesOptions]()
            .then(() => {
                if (!extensionLoaded) {
                    dayjs.extend(LocalizedFormat);
                    extensionLoaded = true;
                }

                dayjs.locale(locale as keyof typeof localesOptions);
                dayjs.extend(RelativeTime);
                document.documentElement.setAttribute('lang', String(locale));
            });
    }

    throw new Error('Locale not available.');
};

export default readonly(localesOptions);
