import deepmerge from 'deepmerge';
import { isObject } from '~/helpers';
import type {
    BaseHttpRequestConfig,
    FetchPayload,
    FinalHttpRequestConfig,
    HttpAbortControllers,
    HttpClientInterface,
    HttpError,
    HttpMethod,
    HttpRequestConfig,
    HttpRequestInterceptor,
    HttpResponseInterceptor,
} from '~/typings/http';
import type { AnyObject, KeyValueObject } from '~/typings/interfaces';

abstract class HttpClient<C extends AnyObject, E> implements HttpClientInterface<C, E> {
    protected static abortControllers: HttpAbortControllers = {
        get: {},
        post: {},
        patch: {},
        put: {},
        delete: {},
    };

    private readonly defaultConfig: BaseHttpRequestConfig = {
        unique: true,
        abortOnRouting: true,
        paginate: false,
        unaltered: false,
    };

    public async get<T>(
        url: string,
        payload?: FetchPayload,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        return this.make('get', url, payload, config);
    }

    public async post<T>(
        url: string,
        payload?: KeyValueObject | FormData,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        return this.make('post', url, payload, config);
    }

    public async patch<T>(
        url: string,
        payload?: KeyValueObject | FormData,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        return this.make('patch', url, payload, config);
    }

    public async put<T>(
        url: string,
        payload?: KeyValueObject | FormData,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        return this.make('put', url, payload, config);
    }

    public async delete<T = void>(
        url: string,
        payload?: KeyValueObject | FormData,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        return this.make('delete', url, payload, config);
    }

    public async create<T>(
        method: HttpMethod,
        url: string,
        data?: FetchPayload | KeyValueObject | FormData,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        return this.make(method, url, data, config);
    }

    public abortPendingRequests(): void {
        for (const [, requests] of Object.entries(HttpClient.abortControllers)) {
            for (const [, request] of Object.entries(requests)) {
                if (request.abortOnRouting) {
                    request.controller.abort();
                }
            }
        }
    }

    public stringifyParameters(
        params: FetchPayload | undefined,
        skipEmpty = true,
    ): string {
        if (!params || Object.entries(params).length === 0) {
            return '';
        }

        const payload: Record<string, string> = {};

        for (const [key, value] of Object.entries(params)) {
            if (key === 'filter') {
                // Prep filter to work with Laravel query builder
                if (Array.isArray(value)) {
                    value.forEach(filter => {
                        const filterArray = String(filter).match(/\[(.+?)]/); // Match between squire brackets

                        if (filterArray) {
                            const filterKey = filterArray[0];
                            const filterValue = String(filter).replace(`${filterKey}=`, '');

                            if (!skipEmpty) {
                                payload[`filter${String(filterKey)}`] = filterValue || '';
                            } else if (skipEmpty && filterValue) {
                                payload[`filter${String(filterKey)}`] = filterValue;
                            }
                        }
                    });
                }
            } else if (!skipEmpty) {
                payload[key] = value ? String(value) : '';
            } else if (skipEmpty && value) {
                payload[key] = String(value);
            }
        }

        const searchParameters = new URLSearchParams(payload).toString();

        return `?${searchParameters}`;
    }

    public isHttpError(error: unknown): error is HttpError<C, E> {
        if (!isObject(error)) {
            return false;
        }

        return error.isHttpError;
    }

    protected async make<T>(
        method: HttpMethod,
        url: string,
        data?: FetchPayload | KeyValueObject | FormData,
        config?: HttpRequestConfig<C>,
    ): Promise<T> {
        const preparedEndpoint = this.prepareEndpoint(method, url, data);
        const preparedConfig = this.prepareConfig(config);

        return this.handleRequestInterceptor(preparedConfig)
            .then(async httpConfig => {
                this.abortPreviousRequestIfNecessary(url, method, httpConfig);

                return this.performRequest<T>(
                    method,
                    preparedEndpoint,
                    data,
                    httpConfig,
                )
                    .catch(error => {
                        if (this.isHttpError(error)) {
                            this.handleResponseInterceptor(error, preparedConfig);
                        }

                        throw error;
                    })
                    .finally(() => {
                        if (HttpClient.abortControllers[method][url]) {
                            delete HttpClient.abortControllers[method][url];
                        }
                    });
            });
    }

    protected abstract performRequest<T>(
        method: HttpMethod,
        endpoint: string,
        data?: FetchPayload | KeyValueObject | FormData,
        config?: FinalHttpRequestConfig<C>,
    ): Promise<T>;

    protected requestInterceptor(): HttpRequestInterceptor<C> | null {
        return null;
    }

    protected responseInterceptor(): HttpResponseInterceptor<C, E> | null {
        return null;
    }

    protected makeErrorObject<CO extends AnyObject, ER>(
        status: number,
        data: unknown,
        config: CO,
        original: ER,
    ): HttpError<CO, ER> {
        return {
            response: {
                status,
                data,
                config: config as unknown as Required<HttpRequestConfig<CO>>,
                original,
            },
            isHttpError: true,
        };
    }

    private async handleRequestInterceptor(
        requestConfig: FinalHttpRequestConfig<C>,
    ): Promise<FinalHttpRequestConfig<C>> {
        const interceptor = this.requestInterceptor();

        if (!interceptor) {
            return requestConfig;
        }

        try {
            const config = interceptor.handle(requestConfig);

            return Promise.resolve(config);
        } catch {
            return Promise.resolve(requestConfig);
        }
    }

    private handleResponseInterceptor(error: HttpError<C, E>, config: FinalHttpRequestConfig<C>): void {
        const responseInterceptor = this.responseInterceptor();

        if (!error.response || !responseInterceptor) {
            throw error;
        }

        const status = error.response.status;
        const method = `on${status}`;

        if (responseInterceptor.onAny) {
            responseInterceptor.onAny(error, config);
        }

        if (responseInterceptor.onClientError && status >= 400 && status < 500) {
            responseInterceptor.onClientError(error, config);
        }

        if (responseInterceptor.onServerError && status >= 500) {
            responseInterceptor.onServerError(error, config);
        }

        if (responseInterceptor[method as keyof HttpResponseInterceptor<C, E>]) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            responseInterceptor[method as keyof HttpResponseInterceptor<C, E>]!(error, config);
        }
    }

    private prepareEndpoint(
        method: HttpMethod,
        url: string,
        data?: FetchPayload | KeyValueObject | FormData,
    ): string {
        return method === 'get' ? url + this.stringifyParameters(data as FetchPayload) : url;
    }

    private prepareConfig(
        config?: HttpRequestConfig<C>,
    ): FinalHttpRequestConfig<C> {
        return deepmerge(this.defaultConfig, config ?? {}, { clone: true }) as FinalHttpRequestConfig<C>;
    }

    private abortPreviousRequestIfNecessary(
        url: string,
        method: HttpMethod,
        config?: HttpRequestConfig<C>,
    ): void {
        if (config?.unique) {
            if (HttpClient.abortControllers[method][url]) {
                HttpClient.abortControllers[method][url].controller.abort();
            }

            const controller = new AbortController();

            HttpClient.abortControllers[method][url] = {
                controller,
                abortOnRouting: !!config.abortOnRouting,
            };
        }
    }
}

export default HttpClient;
