import useAuth from '~/stores/Auth';
import useMiddleware from '~/lib/hooks/middleware';
import { normalizeRoute } from '~/lib/router/RouterPipeline';
import type { AuthorizableDestination } from '~/typings/auth';

export const checkUserIsAuthorizedToVisitRoute = (route: AuthorizableDestination): boolean => {
    if (route.meta?.guest) {
        return true;
    }

    const auth = useAuth();

    if (!auth.user) {
        return false;
    }

    const meta = route.meta;

    if (meta?.permission && !auth.hasAnyPermissionTo(meta.permission)) {
        return false;
    }

    if (meta?.role && !auth.hasAnyRole([...(Array.isArray(meta.role) ? meta.role : [meta.role])])) {
        return false;
    }

    if (meta?.guard && !meta.guard(auth.user, route, {
        hasAnyPermissionTo: auth.hasAnyPermissionTo,
        hasAnyRole: auth.hasAnyRole,
    })) {
        return false;
    }

    return true;
};

const authorize = useMiddleware(async (to, from, next) => {
    if (!checkUserIsAuthorizedToVisitRoute(to)) {
        return normalizeRoute('unauthorized');
    }

    return next(to, from);
});

export default authorize;
