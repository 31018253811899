import type { RouteRecordRaw } from 'vue-router';

const auth: RouteRecordRaw[] = [
    {
        path: '/auth',
        name: 'auth',
        component: async () => import('../views/RouteViewWrapper.vue'),
        meta: {
            guest: true,
        },
        redirect: {
            name: 'auth.login',
        },
        children: [
            {
                path: 'login',
                name: 'auth.login',
                component: async () => import('../views/Auth/Login.vue'),
                meta: {
                    title: 'Login',
                },
            },
            {
                path: 'register',
                name: 'auth.register',
                component: async () => import('../views/Auth/Register.vue'),
                meta: {
                    title: 'Account aanmaken',
                },
            },
            {
                path: 'verify-email',
                name: 'auth.verify-email',
                component: async () => import('../views/Auth/VerifyEmail.vue'),
                meta: {
                    title: 'E-mail verifiëren',
                },
            },
            {
                path: 'verify-email-request/:id/:token',
                name: 'auth.verify-email-request',
                component: async () => import('../views/Auth/VerifyEmailRequest.vue'),
                meta: {
                    title: 'E-mail verifiëren',
                    guest: false,
                },
            },
            {
                path: 'verify-secondary-email-request/:id/:token',
                name: 'auth.verify-secondary-email-request',
                component: async () => import('../views/Auth/VerifySecondaryEmailRequest.vue'),
                meta: {
                    title: 'Tweede E-mail verifiëren',
                    guest: false,
                },
            },
            {
                path: 'forgot-password',
                name: 'auth.forgot-password',
                component: async () => import('../views/Auth/ForgotPassword.vue'),
                meta: {
                    title: 'Wachtwoord herstellen',
                },
            },
            {
                path: 'password-reset/:token',
                name: 'auth.password-reset',
                component: async () => import('../views/Auth/PasswordReset.vue'),
                meta: {
                    title: 'Wachtwoord herstellen',
                },
            },
            {
                path: 'logout',
                name: 'auth.logout',
                component: async () => import('../views/Auth/Logout.vue'),
                meta: {
                    title: 'Logout',
                },
            },
            {
                path: '2fa',
                name: 'auth.2fa',
                component: async () => import('../views/Auth/TwoFactor.vue'),
                meta: {
                    title: 'Twee-Factor Authenticatie',
                },
            },
            {
                path: 'periodic-email-verification',
                name: 'auth.periodic-email-verification',
                component: async () => import('../views/Auth/PeriodicEmailVerification.vue'),
                meta: {
                    title: 'E-mail verifiëren',
                },
            },
        ],
    },
];

export default auth;
