import { sleep } from '~/helpers';
import useState from '~/stores/State';
import type { AnyAsyncFunction } from '~/typings/types';

const useLoading = <F extends AnyAsyncFunction>(
    callback: F,
    delayInMs = 0,
): (...arguments_: Parameters<F>) => Promise<Awaited<ReturnType<F>>> => {
    return async (...arguments_: Parameters<F>): Promise<Awaited<ReturnType<F>>> => {
        const state = useState();
        state.loading = true;

        await sleep(delayInMs);

        return callback(...arguments_)
            .finally(() => {
                state.loading = false;
            });
    };
};

export default useLoading;
