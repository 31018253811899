<template>
    <li v-if="isAuthorizedToView">
        <RouterLink
            v-if="routeName"
            :to="{ name: routeName, params: routeParameters }"
            class="grid grid-cols-[2rem_auto] gap-x-4 items-center px-4 lg:px-6 py-3 focus:bg-wgm-blue-800 outline-none focus:text-white"
            :class="{'text-wgm-gray-200': !isActive, 'hover:bg-wgm-blue-900 focus:bg-wgm-blue-900': !isChild,
                     'hover:bg-wgm-blue-800 focus:bg-wgm-blue-800': isChild, 'focus:bg-wgm-blue-700': isActive}"
            :active-class="'bg-wgm-blue-800 text-white font-bold'"
            :exact-active-class="'bg-wgm-blue-800 text-white font-bold'"
            v-bind="$attrs"
            @keydown.prevent.enter="$router.push({ name: routeName, params: routeParameters })"
        >
            <slot name="icon">
                <component
                    :is="icon"
                    v-if="icon"
                    class="fill-current"
                    height="24"
                    width="24"
                />
            </slot>
            <div v-if="!icon && !slots.icon"></div>
            <span>{{ label }}</span>
        </RouterLink>
        <a
            v-else-if="href"
            :href="href"
            class="grid grid-cols-[2rem_auto] gap-x-4 items-center px-4 lg:px-6 py-3 text-wgm-gray-200 outline-none focus:text-white"
            :class="{'hover:bg-wgm-blue-900 focus:bg-wgm-blue-900': !isChild, 'hover:bg-wgm-blue-800 focus:bg-wgm-blue-800': isChild}"
            v-bind="$attrs"
            @click="state.loading = true"
            @keydown.prevent.enter="forceNavigate(href!)"
        >
            <slot name="icon">
                <component
                    :is="icon"
                    v-if="icon"
                    class="fill-current"
                    height="24"
                    width="24"
                />
            </slot>
            <div v-if="!icon && !slots.icon"></div>
            <span>{{ label }}</span>
        </a>
        <button
            v-else-if="onClick"
            type="button"
            class="w-full grid grid-cols-[2rem_auto] gap-x-4 items-center px-4 lg:px-6 py-3 text-wgm-gray-200 outline-none focus:text-white"
            :class="{'hover:bg-wgm-blue-900 focus:bg-wgm-blue-900': !isChild, 'hover:bg-wgm-blue-800 focus:bg-wgm-blue-800': isChild}"
            v-bind="$attrs"
            @click="onClick"
            @keydown.prevent.enter="onClick"
        >
            <slot name="icon">
                <component
                    :is="icon"
                    v-if="icon"
                    class="fill-current"
                    height="24"
                    width="24"
                />
            </slot>
            <div v-if="!icon && !slots.icon"></div>
            <span class="text-left">{{ label }}</span>
        </button>
    </li>
</template>

<script setup lang="ts">
import { checkUserIsAuthorizedToVisitRoute } from '~/middleware/Authorize';
import useState from '~/stores/State';
import { normalizeRoute } from '~/lib/router/RouterPipeline';
import type { RouteParamsRaw } from 'vue-router';
import type { Component } from 'vue';

interface Props {
    routeName?: string;
    routeParameters?: RouteParamsRaw;
    href?: string;
    onClick?: (event?: Event) => void;
    isChild?: boolean;
    label: string;
    icon?: Component;
}

const props = withDefaults(defineProps<Props>(), {
    routeName: undefined,
    routeParameters: undefined,
    props: undefined,
    href: undefined,
    onClick: undefined,
    icon: undefined,
    isChild: false,
});

const slots = useSlots();
const state = useState();
const route = useRoute();

const isActive = computed(() => {
    if (!props.isChild) {
        return route.name === props.routeName;
    }

    return route.name?.toString().startsWith(props.routeName ?? '');
});

const isAuthorizedToView = computed(() => {
    // If no route group is specified, the route meta can't be found and authorization checks
    // should be set directly on this component.
    if (!props.routeName) {
        return true;
    }

    const destination = normalizeRoute(props.routeName, props.routeParameters);

    if (!checkUserIsAuthorizedToVisitRoute(destination)) {
        return false;
    }

    return true;
});

const forceNavigate = (href: string): void => {
    state.loading = true;
    window.location.href = href;
};
</script>
