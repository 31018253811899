import { Permission } from '~/typings/enums';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'invoice',
        name: 'module.tracking-system.invoice',
        component: async () => import('../views/Invoice/Overview.vue'),
        meta: {
            title: 'Facturen',
            permission: [
                Permission.Module_TrackingSystem_Invoices_ViewAll,
            ],
        },
    },
];

export default routes;
