import type { RouteRecordRaw } from 'vue-router';

const isDevelopment = import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production';

const routes: RouteRecordRaw[] = isDevelopment ? [
    {
        path: '/dev',
        name: 'dev',
        component: async () => import('../views/RouteViewWrapper.vue'),
        redirect: {
            name: 'dev.styleguide.colours',
        },
        children: [
            {
                path: 'styleguide',
                name: 'dev.styleguide',
                component: async () => import('../views/RouteViewWrapper.vue'),
                redirect: {
                    name: 'dev.styleguide.colours',
                },
                children: [
                    {
                        path: 'colours',
                        name: 'dev.styleguide.colours',
                        component: async () => import('../views/Development/Colours.vue'),
                        meta: {
                            title: 'Kleuren',
                        },
                    },
                ],
            },
            {
                path: 'translations',
                name: 'dev.translations',
                component: async () => import('../views/Development/Translations.vue'),
                meta: {
                    title: 'Vertalingen',
                },
            },
            {
                path: 'testing',
                name: 'dev.testing',
                component: async () => import('../views/Development/Testing.vue'),
                meta: {
                    title: 'Testing',
                },
            },
        ],
    },
] : [];

export default routes;
