import useHttp from '~/lib/http';
import useMiddleware from '~/lib/hooks/middleware';

const cancelFetchRequests = useMiddleware(async (to, from, next) => {
    useHttp().abortPendingRequests();

    return next(to, from);
});

export default cancelFetchRequests;
