// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/vue';

const { updateServiceWorker } = useRegisterSW({
    immediate: true,
    onRegisteredSW(_swScriptUrl, registration) {
        if (registration) {
            setInterval(async () => { console.debug('Checking for updates'); await registration.update(); }, 10_000);
            registration?.addEventListener('updatefound', async () => {
                console.debug('Found update start reload timer');
                updateServiceWorker().then(() => {
                    setInterval(() => {
                        console.debug('Reloading');
                        // TODO trigger dialog notification that the update has completed
                        document.location.reload();
                    }, 5000);
                });
            });
        }
    },
});
