import type { TawkApi } from '~/typings/third-party';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default class TawkChat {
    public static readonly scriptId = 'tawk-chat-js';

    public static install(): void {
        if (document.getElementById(TawkChat.scriptId)) {
            return;
        }

        const script = document.createElement('script') as HTMLScriptElement;
        script.id = TawkChat.scriptId;
        script.src = 'https://embed.tawk.to/5666e12ad7a97578329e0a2d/default';
        script.setAttribute('crossorigin', '*');

        document.body?.append(script);
    }

    public static api(): TawkApi | null {
        const api: TawkApi | undefined = (window as any).Tawk_API;

        return api ?? null;
    }
}
