import useAuth from '~/stores/Auth';
import useMiddleware from '~/lib/hooks/middleware';
import { normalizeRoute } from '~/lib/router/RouterPipeline';

const ensureEmailIsVerified = useMiddleware(async (to, from, next) => {
    return useAuth().emailIsNotVerified() && to.name?.toString() !== 'auth.verify-email' && to.name?.toString() !== 'auth.verify-email-request'
        ? normalizeRoute('auth.verify-email')
        : next(to, from);
});

export default ensureEmailIsVerified;
