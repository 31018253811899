import dayjs from 'dayjs';
import { isString, isValidNumber } from '~/helpers';
import type { ValidationRuleFunction } from '~/validation';

export function isValidPhoneNumber(value: unknown): boolean {
    if (!isString(value) && !isValidNumber(value)) {
        return false;
    }

    const stringValue = String(value);

    if (stringValue.length < 6) {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < stringValue.length; index += 1) {
        if (!/\d/.test(stringValue[index])) {
            return false;
        }
    }

    return true;
}

const genericRules: Record<string, ValidationRuleFunction> = {
    contains_number: (value): boolean => isString(value) && /\d/.test(value),
    contains_uppercase: (value): boolean => isString(value) && /[A-Z]/.test(value),
    contains_lowercase: (value): boolean => isString(value) && /[a-z]/.test(value),
    contains_special_char: (value): boolean => isString(value) && /\W|_/.test(value),

    date_before_now: (value): boolean => !value || dayjs(value as string).isBefore(dayjs()),
    date_after_now: (value): boolean => !value || dayjs(value as string).isAfter(dayjs()),
    date_day_today_past: (value): boolean => !value || dayjs(value as string).subtract(1, 'day').isBefore(dayjs(), 'day'),
    date_day_today_future: (value): boolean => !value || dayjs(value as string).add(1, 'day').isAfter(dayjs(), 'day'),

    required_boolean: (value): boolean => typeof value === 'boolean' || value instanceof Boolean,
    one_medicine_required: (value, params): boolean => (!!value || params[0] === 'false'),

    // https://github.com/validatorjs/validator.js/blob/master/src/lib/isMobilePhone.js
    mobile_phone: (value): boolean => isString(value) && /^(((\+|00)?31\(0\))|((\+|00)?31)|0)6\d{8}$/.test(value),

    password_confirmation: (value: unknown): boolean => {
        const password = document.querySelector('input[name="password"]') as HTMLInputElement | null;

        if (!password) {
            return true;
        }

        return password.value === value;
    },
};

export default genericRules;
