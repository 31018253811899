/* eslint-disable class-methods-use-this */
import useMiddleware from '~/lib/hooks/middleware';
import useState from '~/stores/State';
import type { RouteMeta } from 'vue-router';

const setMetaTitle = (meta: RouteMeta): void => {
    const titleTag = document.documentElement.querySelector('title');
    const env = import.meta.env.MODE;
    let envTag: string;

    switch (env) {
        case 'development':
            envTag = '(dev) ';
            break;
        case 'production':
            envTag = '';
            break;
        default:
            envTag = `(${env}) `;
            break;
    }

    if (titleTag) {
        titleTag.textContent = meta.title
            ? `${envTag}${meta.title} | Mijn Westfalen Medical`
            : `${envTag}Mijn Westfalen Medical`;
    }
};

const setHeaderTitle = (state: ReturnType<typeof useState>, meta: RouteMeta): void => {
    if (meta.headerTitle) {
        state.headerTitle = meta.headerTitle;

        return;
    }

    if (meta.title) {
        state.headerTitle = meta.title;

        return;
    }

    state.headerTitle = undefined;
};

const updateStateAfterRouting = useMiddleware(async (to, from, next) => {
    const state = useState();

    state.reset();
    setMetaTitle(to.meta);
    setHeaderTitle(state, to.meta);

    return next(to, from);
});

export default updateStateAfterRouting;
