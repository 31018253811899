import { isProduction, isTest } from '~/helpers';

export default class Log {
    public static debug(message: string, ...context: any[]): void {
        if (isProduction() || isTest()) {
            return;
        }

        console.log(message, ...context);
    }
}
