import { Permission } from '~/typings/enums';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'orders',
        name: 'module.tracking-system.order',
        component: async () => import('../views/Order/Overview.vue'),
        meta: {
            title: 'Bestellingen',
            permission: [
                Permission.Module_TrackingSystem_Orders_View,
            ],
        },
    },
    {
        path: 'orders/create',
        name: 'module.tracking-system.order.create',
        component: async () => import('../views/Order/Create.vue'),
        meta: {
            title: 'Bestelling toevoegen',
            permission: [
                Permission.Module_TrackingSystem_Orders_Create,
            ],
        },
    },
    {
        path: 'orders/:id',
        name: 'module.tracking-system.order.detail',
        component: async () => import('../views/Order/Detail.vue'),
        meta: {
            title: 'Bestelling details',
            permission: [
                Permission.Module_TrackingSystem_Orders_View,
            ],
        },
    },

];

export default routes;
