<template>
    <aside
        class="h-screen fixed z-[100] lg:z-[1] top-0 left-0 transition-transform -translate-x-full lg:translate-x-0
        w-full grid lg:static lg:grid grid-rows-[auto_1fr]
        lg:grid-rows-[5rem_1fr] lg:w-72 text-white bg-wgm-blue-primary"
        :class="{'translate-x-0': state.showMobileMenu}"
    >
        <div
            class="py-2 lg:py-3 px-4 lg:px-6 bg-wgm-gray-100 flex items-center justify-between pt-safe"
        >
            <div class="lg:h-full flex items-center gap-8">
                <RouterLink
                    :to="{ name: 'dashboard' }"
                    class="lg:h-full outline-1 outline-offset-4 outline-sky-400"
                >
                    <img
                        src="../../assets/logo.png"
                        class="lg:h-full w-20 lg:w-auto"
                        alt=""
                    >
                </RouterLink>
                <LoadingSpinner
                    v-if="state.loading"
                    class="scale-75 -translate-y-1"
                />
            </div>
            <button
                type="button"
                class="grid lg:hidden gap-1 cursor-pointer p-2 -my-2 -mr-2"
                @click="state.showMobileMenu = false"
            >
                <span class="block h-[3px] w-6 bg-wgm-gray-800 rotate-45 translate-y-[calc(100%+1px)]"></span>
                <span class="block h-[3px] w-6 bg-wgm-gray-800 -rotate-45 -translate-y-full"></span>
            </button>
        </div>
        <nav class="flex flex-col pt-4 lg:pt-8 gap-y-6 lg:gap-y-8 overflow-y-auto">
            <SidebarNavGroup>
                <SidebarLink
                    route-name="dashboard"
                    :label="$t('dashboard.sidebar.dashboard')"
                    :icon="IconDashboard"
                />
            </SidebarNavGroup>
            <SidebarNavGroup
                v-if="isDevelopment && auth.hasPermissionTo(Permission.Module_TrackingSystem_Global_View) && auth.hasActiveModule(Modules.TrackingSystem)"
                :header="$t('dashboard.sidebar.tracking-system.title')"
            >
                <SidebarLink
                    v-if="auth.hasPermissionTo(Permission.Module_TrackingSystem_Orders_View)"
                    route-name="module.tracking-system.order"
                    :route-parameters="{ caregroupUuid: auth.user?.caregroup_uuids[0] }"
                    :label="$t('dashboard.sidebar.tracking-system.orders')"
                    :icon="IconDelivery"
                />
                <SidebarLink
                    v-if="auth.hasPermissionTo(Permission.Module_TrackingSystem_Locations_ViewAll)"
                    route-name="module.tracking-system.locations"
                    :route-parameters="{ caregroupUuid: auth.user?.caregroup_uuids[0] }"
                    :label="$t('dashboard.sidebar.tracking-system.locations')"
                    :icon="IconLocation"
                />
                <SidebarLink
                    v-if="auth.hasPermissionTo(Permission.Module_TrackingSystem_Stock_View)"
                    route-name="module.tracking-system.stock"
                    :route-parameters="{ caregroupUuid: auth.user?.caregroup_uuids[0] }"
                    :label="$t('dashboard.sidebar.tracking-system.stock')"
                    :icon="IconBoxes"
                />
                <SidebarLink
                    v-if="auth.hasPermissionTo(Permission.Module_TrackingSystem_Invoices_View)"
                    route-name="module.tracking-system.invoice"
                    :route-parameters="{ caregroupUuid: auth.user?.caregroup_uuids[0] }"
                    :label="$t('dashboard.sidebar.tracking-system.invoices')"
                    :icon="IconFiles"
                />
            </SidebarNavGroup>
            <SidebarNavGroup
                :header="$t('dashboard.sidebar.therapy')"
            >
                <SidebarLink
                    v-if="auth.hasPermissionTo(Permission.TherapyGlobal_Overview_View)"
                    route-name="therapy.overview"
                    :label="$t('dashboard.sidebar.therapy_overviews')"
                    :icon="IconEye"
                    data-v-step="sidebar:therapy-overviews"
                />
                <SidebarParentLink
                    v-if="isCaregiver || isCustomerServiceInCaregroup"
                    :label="$t('dashboard.sidebar.manage_therapy_requests')"
                    :icon="IconUsers"
                    route-group="therapy"
                    data-v-step="sidebar:manage-therapy-requests"
                >
                    <template
                        v-for="therapy in Object.values(TherapyType)"
                        :key="therapy"
                    >
                        <SidebarLink
                            :route-name="`therapy.${therapy}`"
                            :label="$t(`therapy.therapy_type.${therapy}`)"
                            :is-child="true"
                        />
                    </template>
                </SidebarParentLink>
            </SidebarNavGroup>
            <SidebarNavGroup
                v-if="isDevelopment && auth.hasRole(Role.Developer)"
                header="Development"
            >
                <SidebarLink
                    href="https://wgm-api.pro.test/telescope"
                    label="Telescope"
                    :icon="IconEye"
                />
                <SidebarParentLink
                    label="Stijl gids"
                    :icon="IconPaintBrush"
                    route-group="dev.styleguide"
                >
                    <SidebarLink
                        route-name="dev.styleguide.colours"
                        label="Kleuren"
                        :is-child="true"
                    />
                </SidebarParentLink>
                <SidebarLink
                    route-name="dev.translations"
                    label="Vertalingen"
                    :icon="IconTranslate"
                />
                <SidebarLink
                    v-if="isLocal"
                    route-name="dev.testing"
                    label="Triggers"
                    :icon="IconFlask"
                />
            </SidebarNavGroup>
            <SidebarNavGroup
                v-if="isDevelopment && !isLocal"
                header="Testing"
            >
                <SidebarLink
                    route-name="dev.testing"
                    label="Triggers"
                    :icon="IconFlask"
                />
            </SidebarNavGroup>
            <SidebarNavGroup :header="$t('dashboard.sidebar.my_westfalen')">
                <SidebarParentLink
                    :label="$t('dashboard.sidebar.users')"
                    :icon="IconUsers"
                    route-group="users"
                >
                    <SidebarLink
                        route-name="users.overview"
                        :label="$t('dashboard.sidebar.users_overview')"
                        :is-child="true"
                    />
                    <SidebarLink
                        route-name="users.create"
                        :label="$t('dashboard.sidebar.users_create')"
                        :is-child="true"
                    />
                    <SidebarLink
                        route-name="users.validate"
                        :label="$t('dashboard.sidebar.users_validate')"
                        :is-child="true"
                    />
                </SidebarParentLink>
                <SidebarLink
                    route-name="patients.overview"
                    :label="$t('dashboard.sidebar.patients_overview')"
                    :icon="IconPatients"
                />
                <SidebarLink
                    route-name="caregroups.overview"
                    :label="$t('dashboard.sidebar.caregroups_overview')"
                    :icon="IconHospital"
                />
                <SidebarLink
                    v-if="belongsToSingleCaregroup"
                    :on-click="() => $router.push({ name: 'caregroups.show', params: { uuid: auth.user?.caregroup_uuids[0] }})"
                    :label="$t('dashboard.sidebar.caregroup_show')"
                    :icon="IconHospital"
                    data-v-step="sidebar:caregroup-show"
                />
                <SidebarParentLink
                    :label="$t('dashboard.sidebar.counter_reading')"
                    :icon="IconGauge"
                    route-group="counter-reading"
                >
                    <SidebarLink
                        route-name="counter-reading.overview"
                        :label="$t('dashboard.sidebar.overview')"
                        :is-child="true"
                    />
                    <SidebarLink
                        route-name="counter-reading.create"
                        :label="$t('dashboard.sidebar.forward')"
                        :is-child="true"
                    />
                </SidebarParentLink>
            </SidebarNavGroup>
            <SidebarNavGroup :header="$t('dashboard.sidebar.my_account')">
                <SidebarLink
                    route-name="my-account"
                    :label="$t('dashboard.sidebar.account_management')"
                    :icon="IconAccountManagement"
                />
                <SidebarLink
                    :on-click="() => auth.logout()"
                    :label="$t('dashboard.sidebar.logout')"
                    :icon="IconLogout"
                />
            </SidebarNavGroup>
        </nav>
        <span class="text-xs pl-2 pb-2">{{ version || 'debug' }}</span>
    </aside>
</template>

<script setup lang="ts">
import useAuth from '~/stores/Auth';
import IconDashboard from '~/components/Icons/IconDashboard.vue';
import IconEye from '~/components/Icons/IconEye.vue';
import IconUsers from '~/components/Icons/IconUsers.vue';
import IconHospital from '~/components/Icons/IconHospital.vue';
import IconLocation from '~/components/Icons/IconLocation.vue';
import IconLogout from '~/components/Icons/IconLogout.vue';
import IconGauge from '~/components/Icons/IconGauge.vue';
import IconAccountManagement from '~/components/Icons/IconAccountManagement.vue';
import IconPaintBrush from '~/components/Icons/IconPaintBrush.vue';
import IconPatients from '~/components/Icons/IconPatients.vue';
import IconTranslate from '~/components/Icons/IconTranslate.vue';
import IconFlask from '~/components/Icons/IconFlask.vue';
import useState from '~/stores/State';
import {
    Modules,
    Permission,
    Role,
    TherapyType,
} from '~/typings/enums';
import { caregiverRoles } from '~/helpers';
import IconDelivery from '~/components/Icons/IconDelivery.vue';
import IconFiles from '~/components/Icons/IconFiles.vue';
import IconBoxes from '~/components/Icons/IconBoxes.vue';

const version = __APP_VERSION__;
const state = useState();
const auth = useAuth();
const isDevelopment = import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production';
const isLocal = import.meta.env.VITE_SERVER_ENVIRONMENT === 'local';

const isCaregiver = computed(() => auth.hasAnyRole(caregiverRoles()));
const isCustomerServiceInCaregroup = computed(() => {
    return auth.hasRole(Role.CustomerService) && auth.user?.caregroup_uuids && auth.user.caregroup_uuids.length > 0;
});
const belongsToSingleCaregroup = computed(() => {
    if (!auth.user || auth.user.caregroup_uuids.length === 0) {
        return false;
    }

    if (auth.hasAnyRole(caregiverRoles())) {
        return true;
    }

    // Customer service employees and pharmacists can belong to a caregroup,
    // but we only want to show the 'My caregroup' page if they're only connected to one.
    if (auth.hasAnyRole([Role.CustomerService, Role.Pharmacist])) {
        return auth.user.caregroup_uuids.length === 1;
    }

    return false;
});
</script>
