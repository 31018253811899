import createModel, { defineModel } from '~/lib/model';
import useHttp from '~/lib/http';

import type { ClassName, Country } from '~/typings/enums';
import type { Model, ModelDefinition, Timestamps } from '~/typings/model';
import type { Uuid } from '~/typings/types';

export interface Address extends Model, Timestamps {
    model_type: ClassName;
    model_uuid: Uuid;
    street: string | null;
    street_number: string | null;
    street_number_addition: string | null;
    zipcode: string | null;
    city: string;
    country_code: Country | null;
    correspondence_street: string | null;
    correspondence_street_number: string | null;
    correspondence_street_number_addition: string | null;
    correspondence_zipcode: string | null;
    correspondence_city: string | null;
    correspondence_country_code: Country | null;
    comment: string | null;
    has_correspondence: boolean;
    ground_floor: boolean;
    elevator_available: boolean;
}

export interface AddressForm {
    street: string | null;
    street_number: string | null;
    street_number_addition: string | null;
    zipcode: string | null;
    city: string;
    country_code: Country | null;
}

const addressModel = createModel('addresses', defineModel<Address>(), {});

export const lookup = async (zipcode: string, streetNumber: number): Promise<AddressForm> => {
    return useHttp().get('/address-lookup', {
        zipcode,
        street_number: streetNumber,
    });
};

const useAddress = (): ModelDefinition<typeof addressModel> => addressModel();

export default useAddress;
