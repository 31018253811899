import { defineStore } from 'pinia';
import config from '~/config';
import type { Prompt } from '~/lib/hooks/prompt';
import type { Toast } from '~/lib/hooks/toast';

const MOBILE_VIEW_CUTOFF = 1024;

interface State {
    loading: boolean;
    locale: string;
    headerTitle?: string;
    showBackdrop: boolean;
    darkenedBackdrop: boolean;
    showMobileMenu: boolean;
    isMobileView: boolean;
    activePrompt?: Prompt;
    prompts: Map<symbol, Prompt>;
    toasts: Map<number, Toast>;
    validationErrors: Record<string, string>;
}

const useState = defineStore('state', {
    state: () => <State>({
        loading: false,
        locale: config.locale,
        headerTitle: undefined,
        showBackdrop: false,
        darkenedBackdrop: true,
        showMobileMenu: false,
        isMobileView: document.body.clientWidth < MOBILE_VIEW_CUTOFF,
        prompts: new Map(),
        toasts: new Map(),
        activePrompt: undefined,
    }),
    actions: {
        reset() {
            this.loading = false;
            this.showBackdrop = false;
            this.showMobileMenu = false;
            this.activePrompt = undefined;
        },
        checkIsMobileView() {
            this.isMobileView = document.body.clientWidth < MOBILE_VIEW_CUTOFF;
        },
    },
});

export default useState;
