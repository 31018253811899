<template>
    <Select
        id="locale"
        no-spacing
        @change="setLocale"
    >
        <option
            value="nl"
            :selected="state.locale === 'nl'"
        >
            🇳🇱 NL
        </option>
    </Select>
</template>

<script setup lang="ts">
import useState from '~/stores/State';
import { loadLocale } from '~/lib/datetime/locales';

const i18n = useI18n();
const state = useState();

const setLocale = (locale: string): void => {
    loadLocale(locale).then(() => {
        localStorage.setItem('locale', locale);
        state.locale = locale;
        i18n.locale.value = locale;
    });
};
</script>
