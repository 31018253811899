import type { TourStep } from '~/typings/types';

export type Tours = Record<string, () => Promise<{readonly default: TourStep[]}>>;

const tours: Tours = {
    sidebar: async () => import('./sidebar'),
    'therapy-overview': async () => import('./therapy-overview'),
};

export const getTourName = (routeName?: string): string | undefined => {
    switch (routeName) {
        case 'dashboard':
            return 'sidebar';
        case 'therapy.overview':
            return 'therapy-overview';
        default:
            return undefined;
    }
};

export const getTourSteps = async (name: string): Promise<TourStep[]> => {
    const tour = tours[name];

    if (!tour) {
        return [];
    }

    const { default: steps } = await tour();

    return steps;
};

export const tourNames = ['sidebar', 'therapy-overview'];
