import type { Ref } from 'vue';

const useClickOutsideEvent = (
    element: Ref<HTMLElement | undefined>,
    ...toggles: Array<Ref<HTMLElement | undefined>>
): Ref<boolean> => {
    const condition = ref(false);

    const handleClick = (event: Event) => {
        if (condition.value
            && !element.value?.contains(event.target as Node | null)
        ) {
            for (const toggle of toggles) {
                if (toggle.value?.contains(event.target as Node | null)) {
                    return;
                }
            }

            condition.value = false;
        }
    };

    onMounted(() => {
        window.addEventListener('click', handleClick);
    });

    onUnmounted(() => {
        window.removeEventListener('click', handleClick);
    });

    return condition;
};

export default useClickOutsideEvent;
