import general from './general.json';
import auth from './auth.json';
import forms from './forms.json';
import dashboard from './dashboard.json';
import views from './views.json';
import permissions from './permissions.json';
import stock from "./stock.json";
import notifications from './notifications.json';
import toasts from './toasts.json';
import therapy from './therapy.json';
import location from './location.json';
import order from './order.json';
import invoice from './invoice.json';
import validation from './validation';

const messages = {
    ...general,
    ...auth,
    ...forms,
    ...dashboard,
    ...views,
    ...permissions,
    ...stock,
    ...notifications,
    ...toasts,
    ...therapy,
    ...invoice,
    ...location,
    ...order,
    validationErrors: validation,
};

export default messages;
