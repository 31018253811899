import createModel, { defineModel } from '~/lib/model';
import type { Model, ModelDefinition } from '~/typings/model';
import type { Uuid } from '~/typings/types';

export interface BigRegistration extends Model {
    uuid: Uuid;
    user_uuid?: Uuid;
    big_number: string;
    big_group_code?: number;
    expired?: boolean;
}

const bigRegistrationModel = createModel('bigregistrations', defineModel<BigRegistration>(), {});

const useBigRegistration = (): ModelDefinition<typeof bigRegistrationModel> => bigRegistrationModel();

export default useBigRegistration;
