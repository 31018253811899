import type { ComputedRef, UnwrapRef } from 'vue';
import type {
    StoreDefinition,
    StoreActions,
    StoreGetters,
    StoreState,
    PiniaCustomStateProperties,
    StateTree,
    _GettersTree,
} from 'pinia';
import type { AnyObject } from '~/typings/interfaces';
import type { Uuid } from '~/typings/types';
import type { FetchPayload } from './http';

export type ModelDefinition<S extends StoreDefinition> =
    StoreActions<ReturnType<S>> &
    StoreGetters<ReturnType<S>> &
    StoreState<ReturnType<S>>;

export const $primaryKey = 'uuid';

export type PrimaryKey = Uuid;

type ExtendedModel<M> = Omit<M, `${typeof $primaryKey}` | 'created_at' | 'updated_at' | 'deleted_at' | 'model_id' | 'model_uuid' | 'model_type'>

type Intersection<A, B> = A & B extends infer U
    ? { [P in keyof U]: U[P] }
    : never;

type NotNullable<T> = {
    [K in keyof T]: T[K] extends NonNullable<T[K]> ? K : never;
}[keyof T];

type Nullable<T> = {
    [K in keyof T]: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type SortingArguments<T> = keyof T | `-${string & keyof T}`

export interface Parameters<K> extends FetchPayload {
    fields?: K[] | string[];
    page?: number;
    pagination?: number;
    sort?: Array<SortingArguments<K[]>> | string[];
    filter?: string[];
}

export type ModelPayload<T> = Intersection<
    Required<Pick<ExtendedModel<T>, NotNullable<ExtendedModel<T>>>>,
    Partial<Pick<ExtendedModel<T>, Nullable<ExtendedModel<T>>>>
>;

export interface Timestamps {
    created_at: string;
    updated_at: string;
}

export interface SoftDelete {
    deleted_at: string | null;
}

export interface Meta {
    current_page: number;
    from: number;
    last_page: number;
    links: Array<{
        url: string;
        label: string;
        active: boolean;
    }>;
    path: string;
    per_page: number;
    to: number;
    total: number;
    next_page_url?: string;
}
export interface Model {
    uuid: PrimaryKey;
    [key: string]: any;
}

export interface ModelState<M> extends StateTree {
    models: Map<PrimaryKey, M>;
    meta: Partial<Meta>;
}

export interface ModelGetters<S extends StateTree, CM> extends _GettersTree<S> {
    collection: (() => Map<PrimaryKey, CM>)
    | ((state: UnwrapRef<S> & PiniaCustomStateProperties<S>) => Map<PrimaryKey, CM>);
}

export interface ModelActions<M extends Model, CM extends Model, C, U> extends AnyObject {
    fetch: <K extends keyof CM>(primaryKey: PrimaryKey, params?: Parameters<K>, stringifyEmptyParameters?: boolean) => Promise<CM>;
    fetchAll: <K extends keyof CM>(
        params?: Parameters<K>, stringifyEmptyParameters?: boolean
    ) => Promise<Map<PrimaryKey, Pick<CM, K>>>;
    findAndFetch: <K extends keyof CM>(
        primaryKey: PrimaryKey, params?: Parameters<K>, stringifyEmptyParameters?: boolean
    ) => ComputedRef<(CM & { fresh: (id: Uuid) => Promise<void>}) | null>;
    lazyFetch: <K extends keyof CM>(
        primaryKey: PrimaryKey, params?: Parameters<K>, stringifyEmptyParameters?: boolean
    ) => [ComputedRef<CM | null>, (id: PrimaryKey) => Promise<void>];
    lazyFetchMany: <K extends keyof CM>(
        primaryKey: PrimaryKey[], params?: Parameters<K>, stringifyEmptyParameters?: boolean
    ) => [ComputedRef<CM[]>, (id: PrimaryKey[]) => Promise<void>];
    find: (primaryKey: PrimaryKey) => CM | null;
    findRaw: (primaryKey: PrimaryKey) => M | null;
    findOrFetch: <K extends keyof CM>(primaryKey: PrimaryKey, params?: Parameters<K>, stringifyEmptyParameters?: boolean) => Promise<CM>;
    create: (payload: C) => Promise<CM>;
    update: (primaryKey: PrimaryKey, payload: U, preSync?: boolean) => Promise<CM>;
    destroy: (primaryKey: PrimaryKey, preSync?: boolean) => Promise<void>;
}
