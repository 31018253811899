import {
    defineStore,
    type StoreState,
    type StoreGetters,
    type StoreActions,
} from 'pinia';
import { useDatetime } from '~/lib/datetime';
import useHttp from '~/lib/http';
import type { RouteLocation } from 'vue-router';
import type { Meta } from '~/typings/model';
import type { i18nKey } from '~/typings/types';

export interface NotificationTemplate {
    title: i18nKey | [i18nKey, Record<string, string>];
    body: i18nKey | [i18nKey, Record<string, string>];
    link: {
        label: i18nKey;
        vue_route: Partial<RouteLocation>;
    } | null;
    actor: {
        uuid: string;
        name: string;
        image: string | null;
    } | null;
}

export interface Notification {
    uuid: string;
    template: NotificationTemplate;
    created_at: string;
    read_at: string | null;
}

type PaginatedNotifications = Meta & { data: Notification[] };

const notificationStore = defineStore('notification', {
    state: () => ({
        notifications: new Map<string, Notification>(),
        meta: null as Meta | null,
        lastReceivedNotification: null as Notification | null,
        lastReceivedNotificationTimestamp: 0,
        currentPaginatedBatch: new Set<string>(),
    }),
    getters: {
        unread(state) {
            return [...state.notifications.values()]
                .filter(item => item.read_at === null)
                .sort((first, second) => useDatetime(second.created_at).unix() - useDatetime(first.created_at).unix());
        },
        unreadCount(state) {
            return [...state.notifications.values()]
                .filter(item => item.read_at === null)
                .length;
        },
        paginatedNotifications(state) {
            // eslint-disable-next-line unicorn/no-array-reduce
            const notifications = [...state.currentPaginatedBatch.values()].reduce((stack: Array<Notification | null>, current) => {
                const notification = state.notifications.get(current);

                if (notification) {
                    stack.push(notification);
                }

                return stack;
            }, []);

            while (notifications.length < 5) {
                notifications.push(null);
            }

            return notifications;
        },
        fiveNewestNotifications(state) {
            const merged = [...state.notifications.values()];
            const sorted = merged.sort((first, second) => useDatetime(second.created_at).unix() - useDatetime(first.created_at).unix());
            sorted.length = 5;

            return sorted;
        },
    },
    actions: {
        async getNotifications(page = 1) {
            return useHttp().get<PaginatedNotifications>('/notifications', { page }, { paginate: true })
                .then(data => {
                    const $data = { ...data };
                    this.currentPaginatedBatch.clear();
                    $data.data.forEach(notification => {
                        this.notifications.set(notification.uuid, notification);
                        this.currentPaginatedBatch.add(notification.uuid);
                    });
                    this.meta = $data;
                });
        },
        addNotification(notification: Omit<Notification, 'read_at'>) {
            const $notification = {
                ...notification,
                read_at: null,
            };

            this.notifications.set(notification.uuid, $notification);
            this.lastReceivedNotification = $notification;
            this.lastReceivedNotificationTimestamp = Date.now();

            if (this.meta) {
                this.meta.total += 1;
            }

            if (this.meta?.current_page !== 1 || window.location.pathname !== '/') {
                return;
            }

            // Live update pagination if user is on dashboard and on page 1 of notifications
            const currentPaginatedBatchUuids = [...this.currentPaginatedBatch.values()];

            if (this.meta.total >= 5) {
                const lastUuidFromCurrentPaginatedBatch = currentPaginatedBatchUuids.pop();

                if (lastUuidFromCurrentPaginatedBatch) {
                    this.currentPaginatedBatch.delete(lastUuidFromCurrentPaginatedBatch);
                }
            }

            currentPaginatedBatchUuids.unshift($notification.uuid);
            this.currentPaginatedBatch = new Set(currentPaginatedBatchUuids);
        },
        async markNotificationAsRead(uuid: string) {
            const { now } = useDatetime();
            const notification = this.notifications.get(uuid);

            if (notification) {
                notification.read_at = now().toString();

                if (!uuid.includes('fake')) {
                    return useHttp().patch(`/notifications/${uuid}`, { is_read: true });
                }

                return Promise.resolve();
            }

            throw new Error('Notification could not be found in the store.');
        },
        async markNotificationAsUnread(uuid: string) {
            const notification = this.notifications.get(uuid);

            if (notification) {
                notification.read_at = null;

                if (!uuid.includes('fake')) {
                    return useHttp().patch(`/notifications/${uuid}`, { is_read: false });
                }

                return Promise.resolve();
            }

            throw new Error('Notification could not be found in the store.');
        },
        async markAllNotificationsAsRead() {
            const { now } = useDatetime();
            const uuids: string[] = [];

            this.notifications.forEach(notification => {
                if (!notification.read_at) {
                    notification.read_at = now().toString();

                    if (!notification.uuid.includes('fake')) {
                        uuids.push(notification.uuid);
                    }
                }
            });

            uuids.forEach(uuid => {
                useHttp().patch(`/notifications/${uuid}`, { is_read: true });
            });
        },
    },
});

type NotificationStore = StoreState<ReturnType<typeof notificationStore>>
    & StoreGetters<ReturnType<typeof notificationStore>>
    & StoreActions<ReturnType<typeof notificationStore>>;

const useNotification = (): NotificationStore => notificationStore();

export default useNotification;
