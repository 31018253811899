import createModel, { defineModel } from '~/lib/model';
import type { Model, ModelDefinition, Timestamps } from '~/typings/model';
import type { Uuid } from '~/typings/types';

export interface CounterReading extends Model, Timestamps {
    uuid: Uuid;
    kwh: number;
    device: string;
    serial_number: string;
    reading_date: string;
}

export interface CreateCounterReadingPayload {
    kwh: string;
    device: string;
    serial_number: string;
    reading_date: string;
}

const counterReadingModel = createModel('counterreading', defineModel<CounterReading, CounterReading, CreateCounterReadingPayload>(), {});

const useCounterReading = (): ModelDefinition<typeof counterReadingModel> => counterReadingModel();

export default useCounterReading;
