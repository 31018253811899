import { isString } from '~/helpers';
import useAuth from '~/stores/Auth';
import useState from '~/stores/State';
import { scrollToFirstInvalidInput } from '~/helpers/forms';
import { getRouter } from '~/lib/router/RouterPipeline';
import useToast from '../hooks/toast';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { Router } from 'vue-router';
import type { HttpError, HttpResponseInterceptor } from '~/typings/http';
import type { ValidationExceptionData } from '~/typings/types';

export default class ResponseInterceptor<C extends AxiosRequestConfig, E extends AxiosError> implements HttpResponseInterceptor<C, E> {
    public on401(): void {
        if (getRouter().currentRoute.value.name) {
            useAuth().setRedirectAfterLoginRoute(getRouter().currentRoute.value);
        }

        useAuth().invalidate(true, 'expired');
    }

    public on403(): void {
        this.router().push({
            name: 'unauthorized',
        });
    }

    public on404(): void {
        this.router().push({
            name: 'not-found',
        });
    }

    public on409(): void {
        this.router().push({
            name: 'auth.verify-email',
        });
    }

    public on419(): void {
        useAuth().invalidate(true, 'expired');
    }

    public on422(error: HttpError<C, E>): void {
        if (!error.response || !(error.response.data as ValidationExceptionData).errors) {
            return;
        }

        const state = useState();
        const validationErrors: Record<string, string> = {};

        for (const [key, messages] of Object.entries((error.response.data as ValidationExceptionData).errors)) {
            const message = isString(messages) ? messages : messages[0];

            validationErrors[key] = message;
        }

        state.validationErrors = validationErrors;

        nextTick(scrollToFirstInvalidInput);

        if (validationErrors?.email === 'auth.login.failed') return;

        useToast({
            type: 'error',
            header: 'Invalid input',
            context: 'Unfortunately we failed to process the request. Some data may be missing or invalid.',
            timeout: false,
        });
    }

    private router(): Router {
        return getRouter();
    }
}
