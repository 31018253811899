/* eslint-disable @typescript-eslint/no-explicit-any */
import Pusher from 'pusher-js';
import useAuth from '~/stores/Auth';
import useHttp from '~/lib/http';
import { BroadcastedEvent, type RealtimeEvents, type ChannelAuthorizationData } from './types';
import eventHandlers from './eventHandlers';
import Log from '../logging/Logger';
import { isMobileDevice } from '~/helpers';

const channels: Record<BroadcastedEvent, string> = {
    [BroadcastedEvent.UpdatedModelEvent]: 'updated-model-event',
    [BroadcastedEvent.DeletedModelEvent]: 'deleted-model-event',
    [BroadcastedEvent.NotificationEvent]: 'notifications.{uuid}',
    [BroadcastedEvent.BroadcastNotificationCreatedEvent]: 'notifications.{uuid}',
    [BroadcastedEvent.LogOutEvent]: 'authentication.{uuid}',
};

const isTest = ['test', 'testing'].includes(import.meta.env.MODE);

const client = (): Pusher => {
    if (isTest) {
        return {} as Pusher;
    }

    return new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        authorizer: channel => {
            return {
                authorize: (socketId, callback) => {
                    useHttp().post<ChannelAuthorizationData>(`${import.meta.env.VITE_WGM_BASE_API}/broadcasting/auth`, {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                        .then(response => callback(null, response))
                        .catch(error => callback(error, null));
                },
            };
        },
    });
};

export const pusher = client();

const realtimeEventsMock = (): RealtimeEvents => {
    return {
        /* eslint-disable @typescript-eslint/no-empty-function,unicorn/empty-brace-spaces */
        subscribe(): void { },
        unsubscribe(): void { },
        /* eslint-enable @typescript-eslint/no-empty-function,unicorn/empty-brace-spaces */
    };
};

const useRealtimeEvents = (): RealtimeEvents => {
    if (isTest) {
        return realtimeEventsMock();
    }

    return {
        subscribe(): void {
            Log.debug('[Pusher]: Subscribing to channels.');

            const auth = useAuth();
            Object.values(channels).forEach(channel => pusher.subscribe(`private-${channel.replace('{uuid}', auth.user?.uuid ?? '')}`));
            Object.entries(eventHandlers).forEach(([event, handler]) => pusher.bind(`App\\Events\\${event}`, handler));

            if (!isMobileDevice()) {
                Notification.requestPermission();
            }
        },
        unsubscribe(): void {
            Log.debug('[Pusher]: Unsubscribing from channels.');

            const auth = useAuth();
            Object.values(channels).forEach(channel => pusher.unsubscribe(`private-${channel.replace('{uuid}', auth.user?.uuid ?? '')}`));
            pusher.unbind_all();
        },
    };
};

export default useRealtimeEvents;
