import useState from '~/stores/State';
import type { i18nKey } from '~/typings/types';

export interface Toast {
    header?: i18nKey;
    context: i18nKey | [i18nKey, Record<string, string>] | Record<string, string>;
    type: 'success' | 'info' | 'error' | 'warning';
    timeout?: number | false;
    toast_id: number;
    mouse_on_toast: boolean;
}

const defaultConfig = {
    timeout: 4000,
};

const removeToast = (id: number) => {
    const state = useState();

    state.toasts.delete(id);
};

const addTimeout = (toast: Toast) => {
    if (toast.timeout !== false || toast.timeout === undefined) {
        setTimeout(() => {
            if (!toast.mouse_on_toast) removeToast(toast.toast_id);
        }, toast.timeout ?? defaultConfig.timeout);
    }
};

const useToast = (config: Omit<Toast, 'toast_id' | 'mouse_on_toast'>): void => {
    const state = useState();
    const toast: Toast = { toast_id: Math.random(), mouse_on_toast: false, ...config };

    state.toasts.set(toast.toast_id, toast);

    watch(() => state.toasts.get(toast.toast_id)?.mouse_on_toast, () => {
        if (!toast.mouse_on_toast) {
            addTimeout(toast);
        }
    });

    addTimeout(toast);
};

export const hasActiveToasts = (): boolean => {
    const state = useState();

    return state.toasts.size > 0;
};

export default useToast;
