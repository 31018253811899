import type { RouteLocationNormalized, RouteParamsRaw, RouteRecordName } from 'vue-router';

export type Destination = RouteLocationNormalized | boolean;

export type Next = (destination: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<Destination>;

export type Carry = (
    destination: RouteLocationNormalized, from: RouteLocationNormalized, next: Next,
) => Promise<Destination>;

export interface Middleware {
    handle: Carry;
}

export type CallableMiddleware = Carry;

export type Pipe = Middleware | CallableMiddleware;

export type RouteNormalizer = (routeName: RouteRecordName, params?: RouteParamsRaw) => RouteLocationNormalized;

export default class Pipeline {
    private destination?: RouteLocationNormalized;
    private from?: RouteLocationNormalized;
    protected pipestack: Pipe[] = [];

    public send(passable: RouteLocationNormalized, from: RouteLocationNormalized): this {
        this.destination = passable;
        this.from = from;

        return this;
    }

    public through(pipes: Pipe[]): this {
        this.pipestack = pipes;

        return this;
    }

    public pipe(...arguments_: Pipe[]): this {
        this.pipestack.push(...arguments_);

        return this;
    }

    public async then(destination: Next): Promise<Destination> {
        const pipeline = [...this.pipes].reverse().reduce(this.carry(), this.prepareDestination(destination));

        if (this.destination === undefined || this.from === undefined) {
            throw new Error('A passable is required to run a pipeline.');
        }

        return pipeline(this.destination, this.from);
    }

    public async thenReturn(): Promise<Destination> {
        return this.then(async (destination: Destination) => destination);
    }

    public get pipes(): Pipe[] {
        return this.pipestack;
    }

    protected prepareDestination(destinationCallback: Next): (destination: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<Destination> {
        return async (destination: RouteLocationNormalized, from: RouteLocationNormalized): Promise<Destination> => {
            return destinationCallback(destination, from);
        };
    }

    protected carry(): (stack: Next, pipe: Pipe) => (destination: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<Destination> {
        return (stack: Next, pipe: Pipe) => {
            return async (
                destination: RouteLocationNormalized,
                from: RouteLocationNormalized,
            ): Promise<Destination> => {
                try {
                    const $carry = pipe instanceof Function
                        ? await pipe(destination, from, stack)
                        : await pipe.handle(destination, from, stack);

                    return this.handleCarry($carry);
                } catch (error) {
                    return this.handleException(destination, from, error);
                }
            };
        };
    }

    protected handleCarry(carry: Destination): Destination {
        return carry;
    }

    protected handleException(passable: Destination, _from: RouteLocationNormalized, error: unknown): Destination {
        throw error;

        return passable;
    }
}
