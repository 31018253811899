import assertAuthenticated from '~/middleware/AssertAuthenticated';
import authorize from '~/middleware/Authorize';
import cancelFetchRequests from '~/middleware/CancelFetchRequests';
import ensureEmailIsVerified from '~/middleware/EnsureEmailIsVerified';
import ensureUserIsApproved from '~/middleware/EnsureUserIsApproved';
import updateStateAfterRouting from '~/middleware/UpdateStateAfterRouting';
import clearTherapySessionStorage from './ClearTherapySessionStorage';
import type { MiddlewareStack } from '~/typings/types';
import type { Pipe } from '~/lib/router/Pipeline';

const pipes: MiddlewareStack<Pipe> = {
    before: [
        cancelFetchRequests,
        ensureEmailIsVerified,
        ensureUserIsApproved,
        assertAuthenticated, // Should come before the 'authorize' middleware
        authorize,
        clearTherapySessionStorage,
    ],
    after: [
        updateStateAfterRouting,
    ],
};

export default pipes;
