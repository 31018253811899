import { SafeArea } from 'capacitor-plugin-safe-area';
import { App } from '@capacitor/app';
import type { SafeAreaInsets } from 'capacitor-plugin-safe-area';
import type { Ref } from 'vue';

interface UseApp {
    waitForInitialization: () => Promise<void>;
    readonly initialized: Ref<boolean>;
    readonly isApp: Ref<boolean>;
    readonly version: Ref<string | undefined>;
    readonly build: Ref<string | undefined>;
    readonly safeAreas: Ref<SafeAreaInsets['insets']>;
}

const initialized = ref(false);
const initializing = ref(false);
const isApp = ref(false);
const version = ref<string>();
const build = ref<string>();

const safeAreas = ref<SafeAreaInsets['insets']>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});

const initSafeAreas = async () => {
    try {
        const safeArea = await SafeArea.getSafeAreaInsets();
        safeAreas.value = safeArea.insets;

        await SafeArea.addListener('safeAreaChanged', ({ insets }) => {
            safeAreas.value = insets;
        });
    } catch {
        //
    }
};

const initApp = async () => {
    try {
        const appInfo = await App.getInfo();
        isApp.value = true;
        version.value = appInfo.version;
        build.value = appInfo.build;
    } catch {
        isApp.value = false;
        version.value = undefined;
        build.value = undefined;
    }
};

const initialize = async () => {
    if (initializing.value || initialized.value) {
        return;
    }

    initializing.value = true;

    await initApp();
    await initSafeAreas();

    initializing.value = false;
    initialized.value = true;
};

const waitForInitialization = async () => {
    if (initialized.value) {
        return;
    }

    await new Promise<void>(resolve => {
        const interval = setInterval(() => {
            if (initialized.value) {
                clearInterval(interval);
                resolve();
            }
        }, 50);
    });
};

const useApp = (): UseApp => {
    initialize();

    watch(() => safeAreas.value, () => {
        for (const [key, value] of Object.entries(safeAreas.value)) {
            document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
        }
    });

    return {
        waitForInitialization,

        initialized: readonly(initialized),
        safeAreas: readonly(safeAreas),
        isApp: readonly(isApp),
        version: readonly(version),
        build: readonly(build),
    };
};

export default useApp;
