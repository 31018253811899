<template>
    <header class="bg-wgm-gray-100 lg:h-20 pt-safe-t">
        <div class="p-4 h-full lg:flex gap-4 justify-between items-center">
            <div class="mb-2 lg:mb-0 flex items-center justify-between gap-4">
                <div>
                    <h2
                        v-if="auth.user?.caregroup_names.length === 1"
                        class="my-0 font-semibold text-md lg:text-xl"
                    >
                        {{ $t('general.header.caregroup') }}: {{ auth.user.caregroup_names[0] }}
                    </h2>
                    <div v-if="auth.user?.caregroup_names && auth.user.caregroup_names.length > 1">
                        <Select
                            id="caregroups"
                            v-model="auth.activeCaregroup"
                            @change="reload"
                        >
                            <option
                                v-for="(caregroupUuid, index) in auth.user?.caregroup_uuids"
                                :key="caregroupUuid"
                                :value="caregroupUuid"
                                :selected="auth.activeCaregroup === caregroupUuid"
                            >
                                {{ auth.user?.caregroup_names[index] }}
                            </option>
                        </Select>
                    </div>
                </div>
                <div class="grid grid-cols-[auto_1fr] items-center gap-x-1">
                    <span class="lg:hidden w-max text-sm font-bold text-wgm-gray-400">
                        {{ auth.user?.first_name }}
                    </span>
                    <button
                        type="button"
                        class="grid lg:hidden gap-1 cursor-pointer p-2 -my-2 -mr-2"
                        @click="state.showMobileMenu = true"
                    >
                        <span class="block h-[3px] w-6 bg-wgm-gray-800"></span>
                        <span class="block h-[3px] w-6 bg-wgm-gray-800"></span>
                        <span class="block h-[3px] w-6 bg-wgm-gray-800"></span>
                    </button>
                </div>
            </div>
            <div class="flex justify-end lg:justify-between lg:items-center gap-2 lg:gap-4 2xl:gap-12">
                <div
                    v-if="auth.user"
                    class="flex items-end lg:items-center gap-2"
                >
                    <Tour v-if="app.initialized.value && !app.isApp.value" />
                    <div class="relative">
                        <button
                            ref="notificationsDropdownToggle"
                            type="button"
                            class="relative h-8 w-8 grid place-content-center cursor-pointer bg-white border
                            rounded-md shadow-md shadow-transparent transition-shadow outline-none border-wgm-gray-400
                            focus:border-sky-400 focus:shadow-sky-200"
                            @click="showNotificationsDropdown = !showNotificationsDropdown"
                            @keydown.prevent.enter="showNotificationsDropdown = !showNotificationsDropdown"
                            @keydown.capture.escape="showNotificationsDropdown = false"
                        >
                            <IconBell
                                class="text-wgm-gray-800 fill-current"
                                :class="{'animate-shake': newNotification}"
                                height="20"
                                width="20"
                            />
                            <span
                                v-if="notification.unreadCount > 0"
                                class="absolute h-5 w-5 rounded-full bg-wgm-red-primary grid place-content-center
                                        -top-1 -right-1 shadow-md text-white text-xs"
                            >{{ notification.unreadCount }}</span>
                        </button>
                        <div ref="notificationsDropdown">
                            <NotificationsDropdown
                                v-if="showNotificationsDropdown"
                                :notifications="notification.unread"
                                @show="onNotificationOpen"
                                @close="showNotificationPopup = false; activeNotification = null;"
                                @keydown.capture.escape="showNotificationsDropdown = false; activeNotification = null;"
                            />
                        </div>
                    </div>
                    <div class="hidden">
                        <HeaderLanguageToggle />
                    </div>
                    <div
                        v-if="isDevelopment"
                        class="max-w-[15rem] truncate"
                    >
                        <Select
                            id="roles"
                            class="truncate"
                            no-spacing
                            :value="auth.user?.email?.split('@')[0]"
                            @change="toggleRole"
                        >
                            <option
                                v-for="role in RoleAccounts"
                                :key="role"
                                :value="role"
                            >
                                {{ prettifyRole(role) }}
                            </option>
                        </Select>
                    </div>
                </div>
                <div class="hidden lg:flex items-center gap-2 relative">
                    <div>
                        <img
                            v-if="auth.user?.avatar_url"
                            :src="auth.user.avatar_url"
                            alt=""
                            class="w-32 h-32 sm:w-10 sm:h-10 rounded-full"
                        >
                        <div v-else>
                            <IconUserAvatar
                                class="text-wgm-gray-800 fill-current"
                                height="40"
                                width="40"
                            />
                        </div>
                    </div>
                    <div
                        ref="accountManagementDropdownToggle"
                        class="cursor-pointer flex items-center gap-1 outline-1 outline-offset-2 outline-sky-600"
                        tabindex="0"
                        @keydown.prevent.enter="showAccountManagementDropdown = !showAccountManagementDropdown; accountManagementDropdown?.focus()"
                        @keydown.prevent.escape="showAccountManagementDropdown = false"
                        @click="showAccountManagementDropdown = !showAccountManagementDropdown"
                    >
                        <span>{{ auth.user?.first_name }}</span>
                        <IconNarrowArrowDown
                            class="text-wgm-gray-800 fill-current"
                            height="12"
                            width="12"
                        />
                    </div>
                    <div
                        v-show="showAccountManagementDropdown"
                        ref="accountManagementDropdown"
                        class="z-50 absolute top-8 right-0 w-72 bg-white border border-wgm-gray-400 rounded-md py-2
                            shadow-lg outline-1 outline-offset-2 outline-sky-600"
                        @keydown.capture.escape="showAccountManagementDropdown = false"
                    >
                        <ul>
                            <li>
                                <RouterLink
                                    :to="{name: 'my-account'}"
                                    class="w-full block p-1 px-2 hover:bg-wgm-blue-100 transition-colors outline-1 outline-offset-2 outline-sky-600"
                                    @click="showAccountManagementDropdown = false"
                                >
                                    {{ $t('dashboard.header.my_account') }}
                                </RouterLink>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    class="w-full block p-1 px-2 hover:bg-wgm-blue-100 transition-colors text-left outline-1 outline-offset-2 outline-sky-600"
                                    @click="auth.logout()"
                                >
                                    {{ $t('dashboard.header.logout') }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
            v-model="showNotificationPopup"
            class="max-w-2xl"
        >
            <NotificationPopup
                v-if="activeNotification"
                :notification="activeNotification"
            />
        </Dialog>
    </header>
</template>

<script setup lang="ts">
import { capitalize } from '~/helpers';
import useClickOutsideEvent from '~/lib/hooks/clickOutside';
import useAuth from '~/stores/Auth';
import useNotification, { type Notification } from '~/stores/Notification';
import useState from '~/stores/State';
import { Role } from '~/typings/enums';
import useApp from '~/composables/useApp';

const auth = useAuth();
const notification = useNotification();
const state = useState();
const app = useApp();
const isDevelopment = computed(() => import.meta.env.VITE_SERVER_ENVIRONMENT !== 'production' && auth.user?.email?.endsWith('@programic.dev'));

const newNotification = ref(false);
const activeNotification = ref<Notification|null>(null);
const showNotificationPopup = ref(false);

const notificationsDropdown = ref<HTMLDivElement>();
const notificationsDropdownToggle = ref<HTMLButtonElement>();
const showNotificationsDropdown = useClickOutsideEvent(notificationsDropdown, notificationsDropdownToggle);

const accountManagementDropdown = ref<HTMLDivElement>();
const accountManagementDropdownToggle = ref<HTMLDivElement>();
const showAccountManagementDropdown = useClickOutsideEvent(accountManagementDropdown, accountManagementDropdownToggle);

const reload = (): void => {
    window.location.reload();
};

const onNotificationOpen = ($notification: Notification): void => {
    showNotificationPopup.value = true;
    showNotificationsDropdown.value = false;
    activeNotification.value = $notification;
    notification.markNotificationAsRead($notification.uuid);
};

watch(() => notification.lastReceivedNotificationTimestamp, () => {
    newNotification.value = true;

    setTimeout(() => {
        newNotification.value = false;
    }, 500);
});

const RoleAccounts = computed(() => (isDevelopment.value ? [
    Role.AccountManager,
    Role.Admin,
    Role.CustomerService,
    `${Role.CustomerService}-zorggroep`,
    Role.Developer,
    Role.Midwife,
    Role.Nurse,
    Role.PolyclinicEmployee,
    Role.Patient,
    Role.Pharmacist,
    Role.Physician,
    Role.PhysicianAssistant,
    Role.Physiotherapist,
    Role.CarePortalManager,
    Role.CarePortalUser,
    Role.CarePortalViewer,
] : []));

async function toggleRole(role: string): Promise<void> {
    if (!isDevelopment.value) {
        return;
    }

    document.body.classList.add('opacity-0');
    await auth.toggleRole(role as Role);
    window.location.href = '/';
}

function prettifyRole(value: string): string {
    return value.split('-').map(capitalize).join(' ');
}

</script>
