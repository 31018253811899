import useAuth from '~/stores/Auth';
import useState from '~/stores/State';
import Pipeline, { type Pipe } from './Pipeline';
import Log from '../logging/Logger';
import type {
    RouteLocationNormalized,
    RouteParamsRaw,
    Router,
    RouteRecordName,
} from 'vue-router';
import type { MiddlewareStack } from '~/typings/types';

class RouterPipeline {
    private static $router: Router;
    private isSkipped = false;
    private isBlocked = false;
    private initialized = false;

    private readonly pipestack: MiddlewareStack<Pipe> = {
        before: [],
        after: [],
    };

    public constructor(router: Router) {
        RouterPipeline.$router = router;
    }

    public make(pipes: MiddlewareStack<Pipe>): void {
        this.pipestack.before = pipes.before;
        this.pipestack.after = pipes.after;

        RouterPipeline.$router.beforeEach(this.startPipeline.bind(this));
        RouterPipeline.$router.afterEach(this.finishPipeline.bind(this));
    }

    public static getRouter(): Router {
        return RouterPipeline.$router;
    }

    public static routeNormalizer(routeName: RouteRecordName, params?: RouteParamsRaw): RouteLocationNormalized {
        try {
            const route = RouterPipeline.$router.resolve({
                name: routeName,
                params,
            });

            if (!route) {
                throw new Error(`Route name "${routeName.toString()}" could not be resolved`);
            }

            return route;
        } catch (error) {
            const message = error instanceof Error ? ` Message: ${error.message}` : '';

            throw new Error(`Route name "${routeName.toString()}" could not be resolved.${message}`);
        }
    }

    private async startPipeline(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        if (this.shouldBlockRouting(to, from)) {
            return this.block(to, from);
        }

        useState().loading = true;

        const destination = await (new Pipeline())
            .send(to, from)
            .through(this.pipes(to))
            .thenReturn();

        if (destination === false) {
            return this.block(to, from);
        }

        if (destination === true) {
            this.logRouting(to, from, `Visiting ${to.fullPath}${from?.fullPath ? ` from ${from.fullPath}.` : ''}`);

            return this.continue();
        }

        if (this.isRedirect(to, destination)) {
            return this.redirect(from, destination);
        }

        this.logRouting(to, from, `Visiting ${to.fullPath}${from?.fullPath ? ` from ${from.fullPath}.` : ''}`);

        return this.continue();
    }

    private async finishPipeline(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        await (new Pipeline())
            .send(to, from)
            .through(this.afterMiddlewares())
            .thenReturn();

        this.initialized = true;
    }

    private pipes(to: RouteLocationNormalized): Pipe[] {
        if (this.shouldSkip(to)) {
            return [];
        }

        return this.pipestack.before;
    }

    private afterMiddlewares(): Pipe[] {
        if (!this.shouldRunAfterMiddlewares()) {
            return [];
        }

        return this.pipestack.after;
    }

    private redirect(from: RouteLocationNormalized, route: RouteLocationNormalized): { name: RouteRecordName } | { path: string } {
        const target = route.name ? { name: route.name } : { path: route.path };

        this.logRouting(route, from, `Redirecting from '${from.fullPath}' to '${route.fullPath}'.`);

        return target;
    }

    private block(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        this.logRouting(to, from, 'Blocked routing');

        this.isBlocked = true;

        return false;
    }

    private continue() {
        return true;
    }

    private isRedirect(
        to: RouteLocationNormalized,
        destination: RouteLocationNormalized,
    ): boolean {
        return !!(destination.name && to.name?.toString() !== destination.name?.toString());
    }

    private shouldBlockRouting(to: RouteLocationNormalized, from: RouteLocationNormalized): boolean {
        if (!this.initialized) {
            return false;
        }

        // Need to load same component with different props
        if (to.path !== '/therapy/request' && to.fullPath === from.fullPath) {
            this.isBlocked = true;

            return true;
        }

        // Block routing if trying to access homepage while on login screen, to prevent pipeline from
        // running while no routing is occurring.
        if (!useAuth().check() && from.name === 'auth.login' && to.name === 'dashboard') {
            this.isBlocked = true;

            return true;
        }

        this.isBlocked = false;

        return false;
    }

    private shouldSkip(to: RouteLocationNormalized): boolean {
        if (to.meta.skipBeforeMiddleware === true) {
            this.isSkipped = true;

            return true;
        }

        this.isSkipped = false;

        return false;
    }

    private shouldRunAfterMiddlewares(): boolean {
        return !this.isBlocked
            && !this.isSkipped;
    }

    private logRouting(to: RouteLocationNormalized, from: RouteLocationNormalized, message: string): void {
        if (to.path === from.path) {
            return;
        }

        Log.debug(`[Router pipeline]: ${message}`);
    }
}

export function getRouter(): Router {
    return RouterPipeline.getRouter();
}

export function normalizeRoute(routeName: RouteRecordName, params?: RouteParamsRaw): RouteLocationNormalized {
    try {
        const route = RouterPipeline.getRouter().resolve({
            name: routeName,
            params,
        });

        if (!route) {
            throw new Error(`Route name "${routeName.toString()}" could not be resolved`);
        }

        return route;
    } catch (error) {
        const message = error instanceof Error ? ` Message: ${error.message}` : '';

        throw new Error(`Route name "${routeName.toString()}" could not be resolved.${message}`);
    }
}

export default function withPipeline(router: Router, pipes: MiddlewareStack<Pipe>): Router {
    new RouterPipeline(router).make(pipes);

    return router;
}
