import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/status',
        name: 'status',
        component: async () => import('../views/ErrorPages/UserStatus.vue'),
        meta: {
            title: 'Status',
        },
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: async () => import('../views/ErrorPages/Unauthorized.vue'),
        meta: {
            title: 'U bent niet geautoriseerd om de gevraagde pagina te bezoeken.',
            headerTitle: 'Niet geautoriseerd',
        },
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: async () => import('../views/ErrorPages/PageNotFound.vue'),
        meta: {
            title: 'Pagina niet beschikbaar',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: 'not-found',
        },
    },
];

export default routes;
