<template>
    <div v-if="hasFilledSlot">
        <ul
            ref="list"
            class="grid gap-y-1 transition-all duration-[600ms] ease-out lg:overflow-hidden"
            :class="{'max-h-[0]': collapsable, 'max-h-screen': collapsable && open}"
            :aria-expanded="collapsable ? open : undefined"
        >
            <SidebarSubheader
                v-if="header"
                :header="header"
            />
            <slot></slot>
        </ul>
    </div>
</template>

<script setup lang="ts">
interface Props {
    header?: string;
    collapsable?: boolean;
    open?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    header: undefined,
    collapsable: false,
    open: false,
});

const list = ref<HTMLUListElement>();
const hasFilledSlot = ref(true);

onMounted(() => {
    hasFilledSlot.value = !!list.value && list.value.children.length > (props.header ? 1 : 0);
});
</script>
