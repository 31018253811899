<template>
    <button
        type="button"
        class="outline-1 outline-offset-4 rounded disabled:bg-wgm-gray-800 disabled:cursor-not-allowed font-bold font-condensed flex items-center gap-2
            justify-center"
        :class="[themes[theme], sizes[size]]"
    >
        <slot name="trailingIcon"></slot>
        <template v-if="label">
            {{ label }}
        </template>
        <slot v-else></slot>
    </button>
</template>

<script setup lang="ts">
type Theme = 'primary' | 'secondary' | 'delete' | 'link';
type Size = 'sm' | 'md';

interface Props {
    label?: string;
    theme?: Theme;
    size?: Size;
}

withDefaults(defineProps<Props>(), {
    label: undefined,
    theme: 'primary',
    size: 'md',
});

const themes: Record<Theme, string> = reactive({
    primary: 'bg-wgm-blue-primary hover:bg-wgm-blue-900 outline-sky-400 text-white',
    secondary: 'bg-wgm-gray-100 hover:bg-wgm-gray-200 outline-gray-200 text-wgm-gray-800',
    delete: 'bg-wgm-red-primary hover:bg-wgm-red-800 outline-red-400 text-white',
    link: 'hover:underline text-wgm-gray-800 outline-sky-400',
});

const sizes: Record<Size, string> = reactive({
    sm: 'px-4 py-1 ',
    md: 'px-4 py-2 text-lg lg:px-6 lg:py-3 lg:text-xl',
});
</script>
