import appConfig from '~/config';
import useAuth from '~/stores/Auth';
import type { AxiosRequestConfig } from 'axios';
import type { FinalHttpRequestConfig, HttpRequestInterceptor } from '~/typings/http';

export default class RequestInterceptor<C extends AxiosRequestConfig> implements HttpRequestInterceptor<C> {
    public handle(
        config: FinalHttpRequestConfig<C>,
    ): FinalHttpRequestConfig<C> {
        const auth = useAuth();
        const requestConfig = {
            baseURL: appConfig.api,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-App-Locale': appConfig.locale || appConfig.fallbackLocale,
                'X-Caregroup-Id': auth.activeCaregroup ?? undefined,
                Authorization: auth.accessToken ? `Bearer ${auth.accessToken}` : undefined,
            },
            withCredentials: true,
            ...config,
        };

        return requestConfig;
    }
}
