<template>
    <div></div>
</template>

<script setup lang="ts">
import useState from '~/stores/State';

const state = useState();
state.loading = true;

onUnmounted(() => {
    state.loading = false;
});
</script>
