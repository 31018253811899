/* eslint-disable import/prefer-default-export */
export function scrollToFirstInvalidInput(): void {
    const firstInvalidInput = document.querySelector(':invalid') ?? document.querySelector(
        '[data-type="validation-error-message"]',
    );

    if (firstInvalidInput) {
        firstInvalidInput.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    }
}

export function buildFormData(formData: FormData, data?: Record<string, any>, parentKey?: string): void {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
            const value = data[key];
            const _key = parentKey ? `${parentKey}[${key}]` : key;

            if (value === undefined) {
                return;
            }

            buildFormData(formData, value, _key);
        });
    } else if (parentKey) {
        const value = data == null ? '' : data;
        formData.append(parentKey, value as any);
    }
}
