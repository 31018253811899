import * as veeValidateRules from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import genericRules from '~/validation/rules/generic';
import ibanRules from '~/validation/rules/iban';
import bigRules from '~/validation/rules/big';
import socialSecurityNumberRules from '~/validation/rules/social-security-number';
import type { App, Plugin } from 'vue';
import type { FieldValidationMetaInfo } from '@vee-validate/i18n';

export type ValidationRuleFunction<TValue = unknown, TParameters = unknown[] | Record<string, unknown> | any> =
    (value: TValue, params: TParameters, context: FieldValidationMetaInfo) => boolean | string | Promise<boolean | string>;

export const rules: Record<string, ValidationRuleFunction> = {
    alpha: veeValidateRules.alpha,
    alpha_dash: veeValidateRules.alpha_dash,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    alpha_num: veeValidateRules.alpha_num,
    alpha_spaces: veeValidateRules.alpha_spaces,
    between: veeValidateRules.between,
    confirmed: veeValidateRules.confirmed,
    digits: veeValidateRules.digits,
    dimensions: veeValidateRules.dimensions,
    email: veeValidateRules.email,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    ext: veeValidateRules.ext,
    image: veeValidateRules.image,
    integer: veeValidateRules.integer,
    is: veeValidateRules.is,
    is_not: veeValidateRules.is_not,
    length: veeValidateRules.length,
    max: veeValidateRules.max,
    max_value: veeValidateRules.max_value,
    mimes: veeValidateRules.mimes,
    min: veeValidateRules.min,
    min_value: veeValidateRules.min_value,
    not_one_of: veeValidateRules.not_one_of,
    numeric: veeValidateRules.numeric,
    one_of: veeValidateRules.one_of,
    regex: veeValidateRules.regex,
    required: veeValidateRules.required,
    size: veeValidateRules.size,
    url: veeValidateRules.url,

    ...genericRules,
    ...ibanRules,
    ...bigRules,
    ...socialSecurityNumberRules,
};

const validation: Plugin = {
    install: <T>(app: App<T>): App<T> => {
        const translate = app.config.globalProperties.$t;

        Object.keys(rules).forEach(rule => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defineRule(rule, rules[rule]);
        });

        configure({
            generateMessage: context => {
                const params: Record<string, any> = {};

                Object.values(context.rule?.params ?? [])
                    .forEach((parameter: any, index) => {
                        if (parameter === null || parameter === undefined) {
                            return;
                        }

                        params[`${context.rule?.name}_${index}`] = parameter;
                    });

                const key = `validationErrors.${context.rule?.name}`;

                let field = context.field;

                if (field.length > 2) {
                    const lastTwoChars = field.slice(-2);
                    const last = lastTwoChars[1];
                    const secondToLast = lastTwoChars[0];

                    if (secondToLast === '_' && /\d/.test(last)) {
                        field = 'This field';
                    }
                }

                return translate(key, {
                    field: translate(field),
                    value: context.value,
                    ...params,
                });
            },
        });

        return app;
    },
};

export default validation;
