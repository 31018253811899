import { TherapyType } from '~/typings/enums';
import type { RouteRecordRaw } from 'vue-router';

const therapyTypeChildren = (): RouteRecordRaw[] => {
    return Object.values(TherapyType).map(therapy => {
        return {
            path: `request/${therapy}/:therapy_uuid?`,
            name: `therapy.${therapy}`,
            component: async () => import('../views/Therapies/Main.vue'),
            props: { setTherapyType: therapy },
        };
    });
};

const routes: RouteRecordRaw[] = [
    {
        path: '/therapy',
        name: 'therapy',
        component: async () => import('../views/RouteViewWrapper.vue'),
        meta: {
            title: 'Therapie verzoek',
            guest: true,
        },
        redirect: {
            name: 'therapy.main',
        },
        children: [
            ...therapyTypeChildren(),
            {
                path: 'request',
                name: 'therapy.main',
                component: async () => import('../views/Therapies/Main.vue'),
            },
            {
                path: 'request/success',
                name: 'therapy.success',
                component: async () => import('../views/Therapies/RequestSuccess.vue'),
            },
            {
                path: 'overview',
                name: 'therapy.overview',
                component: async () => import('../views/Therapies/TherapiesOverview.vue'),
                meta: {
                    title: 'Overzicht therapie-aanvragen',
                },
            },
            {
                path: ':uuid',
                name: 'therapy.detail',
                component: async () => import('../views/Therapies/TherapyDetailView.vue'),
                meta: {
                    title: 'Therapie bekijken',
                },
            },
        ],
    },
];

export default routes;
