import type { NumberFormats } from "@intlify/core-base";

const numberFormats: NumberFormats = {
    'nl': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
        },
    },
};

export default numberFormats;
