export default {
    _default: '{field} is ongeldig',
    alpha: '{field} mag alleen letters bevatten',
    alpha_dash: '{field} mag alleen letters, nummers, en streepjes bevatten',
    alpha_num: '{field} mag alleen letters en nummers bevatten',
    alpha_spaces: '{field} mag alleen letters en spaties bevatten',
    between: '{field} moet tussen {between_0} en {between_1} liggen',
    confirmed: '{field} komt niet overeen',
    digits: '{field} moet een nummer zijn en exact {digits_0} tekens bevatten',
    dimensions: '{field} moet {dimensions_0} pixels breed zijn en {dimensions_1} pixels hoog',
    email: '{field} moet een geldig e-mailadres zijn',
    mobile_phone: '{field} moet een geldig telefoonnummer zijn',
    not_one_of: '{field} is ongeldig',
    ext: '{field} moet een geldig bestand zijn',
    image: '{field} moet een afbeelding zijn',
    one_of: '{field} moet een geldige waarde zijn',
    max: '{field} mag niet groter zijn dan {max_0} karakters',
    max_value: '{field} mag maximaal {max_value_0} zijn',
    mimes: '{field} moet een geldig bestandstype hebben',
    min: '{field} moet minimaal {min_0} karakters zijn',
    min_value: '{field} moet minimaal {min_value_0} zijn',
    numeric: '{field} mag alleen nummers bevatten',
    regex: '{field} formaat is ongeldig',
    required: '{field} is verplicht',
    required_if: '{field} is verplicht',
    required_boolean: '{field} is verplicht',
    size: '{field} mag niet groter zijn dan {size_0}KB',

    contains_number: '{field} moet minimaal 1 nummer bevatten',
    contains_uppercase: '{field} moet minimaal 1 hoofdletter bevatten',
    contains_lowercase: '{field} moet minimaal 1 kleine letter bevatten',
    contains_special_char: '{field} moet minimaal 1 speciaal teken bevatten',

    date_before_now: '{field} mag niet in de toekomst liggen',
    date_after_now: '{field} mag niet in het verleden liggen',
    date_day_today_past: '{field} mag niet in de toekomst liggen',
    date_day_today_future: '{field} mag niet in het verleden liggen',

    iban_valid: '{field} moet een geldig IBAN bevatten',
    big: '{field} moet een geldig BIG nummer bevatten',
    social_security_number: 'Een bsn moet een reeks van 9 cijfers zijn die de elfproef doorstaat',
    eleven_test: '{field} doorstaat de elfproef niet',

    unique: '{field} is al in gebruik',
    url: '{field} moet een geldige URL zijn, die met http(s):// begint',
    password_confirmation: 'De ingevoerde wachtwoorden komen niet overeen',
    one_medicine_required: 'Vul minimaal één medicatie in.',
}
