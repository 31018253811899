<template>
    <div
        id="prompt"
        class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-md
            shadow-lg p-8 min-w-[24rem] z-50"
    >
        <h3 class="font-bold text-2xl mb-1">
            {{ header ? $t(header) : $t(state.activePrompt?.header ?? '') }}
        </h3>
        <p class="max-w-xl mb-8">
            {{ message ? $t(message) : $t(state.activePrompt?.prompt ?? '') }}
        </p>
        <div class="flex justify-end gap-2">
            <button
                data-test-prompt-confirm
                type="button"
                class="px-6 py-3 bg-wgm-blue-primary border-2 border-transparent transition-colors
                        text-white hover:bg-wgm-blue-900 rounded outline-1 outline-offset-4 outline-sky-400"
                @click="$onConfirm"
            >
                {{ confirmButton ? $t(confirmButton) : $t(state.activePrompt?.confirmButton ?? 'general.defaults.prompt.confirm') }}
            </button>
            <button
                data-test-prompt-cancel
                type="button"
                class="px-6 py-3 border-2 border-wgm-red-primary text-wgm-red-primary
                    hover:bg-wgm-red-800 rounded transition-colors hover:text-white outline-1 outline-offset-4 outline-wgm-red-primary"
                @click="$onCancel"
            >
                {{ cancelButton ? $t(cancelButton) : $t(state.activePrompt?.cancelButton ?? 'general.defaults.prompt.cancel') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import useState from '~/stores/State';
import type { i18nKey } from '~/typings/types';

interface Props {
    header?: i18nKey;
    message?: i18nKey;
    confirmButton?: i18nKey;
    cancelButton?: i18nKey;
    onConfirm?: (() => void) | (() => Promise<void>);
    onCancel?: (() => void) | (() => Promise<void>);
}

const props = defineProps<Props>();
const state = useState();

const $onConfirm = async (): Promise<void> => {
    state.showBackdrop = false;

    if (props.onConfirm) {
        return props.onConfirm();
    }

    state.activePrompt?.onConfirm();
    state.activePrompt = undefined;

    return Promise.resolve();
};

const $onCancel = async (): Promise<void> => {
    state.showBackdrop = false;

    if (props.onCancel) {
        return props.onCancel();
    }

    if (state.activePrompt?.onCancel) {
        state.activePrompt?.onCancel();
    }

    state.activePrompt = undefined;

    return Promise.resolve();
};

</script>
