import createModel, { defineModel } from '~/lib/model';
import type { Model, ModelDefinition, Timestamps } from '~/typings/model';

import type { ClassName, PhoneNumberType } from '~/typings/enums';
import type { Uuid } from '~/typings/types';

export interface PhoneNumber extends Model, Timestamps {
    model_type: ClassName;
    model_uuid: Uuid;
    phone_type: PhoneNumberType;
    number: string;
}

const phoneNumberModel = createModel('phonenumbers', defineModel<PhoneNumber>(), {});

const usePhoneNumber = (): ModelDefinition<typeof phoneNumberModel> => phoneNumberModel();

export default usePhoneNumber;
