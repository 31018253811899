/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useState from '~/stores/State';
import type { useField } from 'vee-validate';
import type { ComputedRef } from 'vue';

type ValidationOptions = {
    id: string;
    name?: string;
    required?: boolean;
    rules?: string | Record<string, any>;
}

type VeeValidateOptions = Partial<ReturnType<typeof useField>>

export default function useValidation(
    {
        id, name, required, rules,
    }: ValidationOptions,
    { errorMessage }: VeeValidateOptions = {},
) {
    const state = useState();
    const { t: translate, te: translationExists } = useI18n();
    let _isRequired: ComputedRef<boolean> | undefined;
    let _validationMessage: ComputedRef<string | undefined> | undefined;

    return {
        get isRequired() {
            if (!_isRequired) {
                _isRequired = computed(() => {
                    if (required) {
                        return true;
                    }

                    if (typeof rules === 'string') {
                        return rules.split('|').includes('required');
                    }

                    if (Array.isArray(rules)) {
                        return rules.includes('required');
                    }

                    if (typeof rules === 'object' && rules !== null) {
                        return 'required' in rules && !!rules.required;
                    }

                    return false;
                });
            }

            return _isRequired;
        },
        get validationMessage() {
            if (!_validationMessage) {
                _validationMessage = computed(() => {
                    if (state.validationErrors?.[name || id]) {
                        return translationExists(state.validationErrors[name || id])
                            ? translate(state.validationErrors[name || id])
                            : state.validationErrors[name || id];
                    }

                    return errorMessage?.value;
                });
            }

            return _validationMessage;
        },
        clearServerValidationError() {
            if (!state.validationErrors) {
                return;
            }

            if (name && state.validationErrors[name]) {
                delete state.validationErrors[name];
            } else if (id && state.validationErrors[id]) {
                delete state.validationErrors[id];
            }
        },
    };
}
