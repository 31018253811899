import useAuth from '~/stores/Auth';
import useMiddleware from '~/lib/hooks/middleware';
import { normalizeRoute } from '~/lib/router/RouterPipeline';
import Log from '~/lib/logging/Logger';
import type { RouteLocationNormalized } from 'vue-router';

// Only check if last auth check was x-amount or more minutes ago, so the checks
// aren't done too frequently in case of frequent or fast routing between pages.
const MINUTES_SINCE_LAST_CHECK = 15;

let lastAuthenticationCheck = Date.now();

const shouldValidate = (
    authenticated: boolean,
    _from: RouteLocationNormalized,
    to: RouteLocationNormalized,
): boolean => {
    if (!authenticated || to.meta.guest) {
        return false;
    }

    if (Date.now() - lastAuthenticationCheck < (MINUTES_SINCE_LAST_CHECK * 1000 * 60)) {
        return false;
    }

    return true;
};

const assertUserIsStillAuthenticated = (to: RouteLocationNormalized, from: RouteLocationNormalized): [RouteLocationNormalized, boolean] => {
    const { check, validate } = useAuth();
    const targetRoute = to.name?.toString();
    const authenticated = check();
    let redirectTarget: string | undefined;

    // Redirect to login page if user is not authenticated and trying to
    // access a page protected by the authentication layer.
    if (!authenticated && !targetRoute?.startsWith('auth') && !to.meta.guest && targetRoute !== 'auth.2fa') {
        redirectTarget = 'auth.login';
    }

    // Redirect to homepage if user is authenticated and trying to
    // access an auth page.
    if (authenticated && targetRoute?.startsWith('auth')) {
        redirectTarget = 'dashboard';
    }

    // Perform async auth check but don't await the result, so the script isn't halted
    // in case the user is still authenticated.
    if (shouldValidate(authenticated, from, to)) {
        lastAuthenticationCheck = Date.now();

        validate();
    }

    if (redirectTarget) {
        Log.debug(`[AssertAuthenticated middleware]: Redirecting to redirect target '${redirectTarget}'`);
    }

    return redirectTarget ? [normalizeRoute(redirectTarget), true] : [to, false];
};

const assertAuthenticated = useMiddleware(async (to, from, next) => {
    const [destination, isRedirect] = assertUserIsStillAuthenticated(to, from);

    return isRedirect ? destination : next(destination, from);
});

export default assertAuthenticated;
