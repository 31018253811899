<template>
    <div class="top-0 left-0 bottom-0 right-0 flex flex-col contain-layout contain-size contain-style absolute justify-between z-0">
        <div
            id="header"
            class="top-0"
        ></div>
        <div
            id="content"
            class="overflow-auto"
        >
            <div
                class="transition-opacity"
                :class="{'opacity-50 select-none pointer-events-none': state.loading}"
            >
                <template v-if="$route.name?.toString().startsWith('auth.') || ($route.meta.guest && auth.isGuest)">
                    <div class="bg-white flex flex-col">
                        <AuthHeader />
                        <div
                            class=" pt-2 lg:pt-10 mb-10 px-8 md:mb-20"
                            :class="{'flex flex-grow justify-center items-center':
                                ($route.name?.toString().startsWith('auth.') || $route.name?.toString() === 'therapy.success')}"
                        >
                            <RouterView
                                v-slot="{ Component }"
                                :key="$route.path"
                            >
                                <Suspense>
                                    <template #default>
                                        <div>
                                            <component :is="Component" />
                                        </div>
                                    </template>
                                    <template #fallback>
                                        <Loader />
                                    </template>
                                </Suspense>
                            </RouterView>
                        </div>
                        <AuthFooter />
                    </div>
                </template>
                <template v-else-if="auth.check()">
                    <div class="lg:grid lg:grid-cols-[auto_1fr] h-screen overflow-toggle-tour">
                        <DashboardSidebar />
                        <div
                            class="h-full grid"
                        >
                            <DashboardHeader />
                            <main
                                class="flex-grow p-4 pb-20 overflow-toggle-tour-scroll
                                    lg:p-8 lg:h-[calc(100vh-5rem)]"
                            >
                                <div class="max-w-[100rem] relative">
                                    <h2
                                        v-if="state.headerTitle"
                                        class="text-lg mb-2 font-semibold lg:text-2xl"
                                    >
                                        {{ state.headerTitle }}
                                    </h2>
                                    <hr
                                        v-if="state.headerTitle"
                                        class="py-2 lg:py-4"
                                    >
                                    <RouterView
                                        v-slot="{ Component }"
                                        :key="$route.path"
                                    >
                                        <Suspense>
                                            <template #default>
                                                <div>
                                                    <component :is="Component" />
                                                </div>
                                            </template>
                                            <template #fallback>
                                                <Loader />
                                            </template>
                                        </Suspense>
                                    </RouterView>
                                </div>
                            </main>
                        </div>
                    </div>
                </template><DynamicDialog />
                <Backdrop v-if="state.showBackdrop" />
                <Prompt v-if="state.activePrompt" />
                <Toast /> <!-- Can't be a v-if because of the transition to work  -->
            </div>
        </div>
        <div
            id="footer"
            class="bottom-0"
        ></div>
    </div>
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog';
import useState from '~/stores/State';
import useAuth from '~/stores/Auth';
import useNotification from '~/stores/Notification';
import { isString } from '~/helpers';
import useApp from '~/composables/useApp';
import type { i18nKey } from '~/typings/types';

useApp();
const state = useState();
const auth = useAuth();
const notification = useNotification();
const { t: translate } = useI18n();

window.addEventListener('resize', () => {
    state.checkIsMobileView();
});

// We must do this here instead of the notifications event handler, in order to use the i18n translate function,
// which can only be called in the script setup.
watch(() => notification.lastReceivedNotification, newNotification => {
    if (!newNotification) {
        return;
    }

    const title: [i18nKey, Record<string, string>] = isString(newNotification.template.title)
        ? [newNotification.template.title, {}]
        : newNotification.template.title;
    const body: [i18nKey, Record<string, string>] = isString(newNotification.template.body)
        ? [newNotification.template.body, {}]
        : newNotification.template.body;
    const $notification = new Notification(translate(...title), {
        body: translate(...body),
        icon: `${window.location.origin}/images/logo.png`,
    });

    setTimeout(() => {
        $notification.close();
    }, 10_000);
});
</script>
