<template>
    <Accordeon
        v-if="isAuthorizedToView"
        class="hover:bg-wgm-blue-900 focus:bg-wgm-blue-900 focus:text-white"
        :class="{'bg-wgm-blue-900': isOpen}"
        :default-open="isOpen"
        :open-condition="isOpen"
        :header-class="['px-4 lg:px-6 py-3 focus:bg-wgm-blue-900 outline-none focus:text-white', {'focus:bg-wgm-blue-700': isOpen}]"
        :block-toggling="blockToggling"
        @toggled="(value: boolean) => { isOpen = value}"
        @open="isOpen = true"
        @on-header-click="onHeaderClick"
    >
        <template #header>
            <div
                class="w-full grid grid-cols-[2rem_auto] gap-x-4 items-center focus:text-white outline-none focus:bg-wgm-blue-900"
                :class="{'text-white': isOpen, 'text-wgm-gray-200': !isOpen}"
            >
                <slot name="icon">
                    <component
                        :is="icon"
                        v-if="icon"
                        class="fill-current"
                        height="24"
                        width="24"
                    />
                </slot>
                <slot name="heading">
                    <span>{{ label }}</span>
                </slot>
            </div>
        </template>
        <template #content>
            <slot></slot>
        </template>
    </Accordeon>
</template>

<script setup lang="ts">
import { isTouchScreen } from '~/helpers';
import { checkUserIsAuthorizedToVisitRoute } from '~/middleware/Authorize';
import { normalizeRoute } from '~/lib/router/RouterPipeline';
import type { RouteRecordNormalized } from 'vue-router';
import type { Component } from 'vue';

interface Props {
    label?: string;
    icon?: Component;
    defaultOpen?: boolean;
    routeGroup?: string;
}

const props = withDefaults(defineProps<Props>(), {
    label: undefined,
    icon: undefined,
    defaultOpen: false,
    routeGroup: undefined,
});

const isOpen = ref(props.defaultOpen);
const route = useRoute();
const router = useRouter();
const parentRoute = props.routeGroup ? normalizeRoute(props.routeGroup) : null;
const childRoutes = router.getRoutes()
    .find(__route__ => __route__.name === props.routeGroup)
    ?.children
    ?.reduce((stack: RouteRecordNormalized[], __child__) => {
        const child = router.getRoutes().find(__route__ => __route__.name === __child__.name);

        if (child) {
            stack.push(child);
        }

        return stack;
    }, [])
    ?? [];

const canBeRedirectedToParentRedirectTarget = (): boolean => {
    const redirectTarget = router.getRoutes()
        .find(__route__ => __route__.name === props.routeGroup)
        ?.redirect;

    if (!redirectTarget) {
        return true;
    }

    if (childRoutes.length === 0) {
        return true;
    }

    const redirectTargetNormalized = normalizeRoute((redirectTarget as { name: string }).name);

    if (!checkUserIsAuthorizedToVisitRoute(redirectTargetNormalized)) {
        return false;
    }

    return true;
};

const onHeaderClick = (): void => {
    if (!props.routeGroup) {
        return;
    }

    if (!isTouchScreen() && canBeRedirectedToParentRedirectTarget()) {
        router.push({ name: props.routeGroup });
    }
};

const blockToggling = computed(() => {
    if (!props.routeGroup) {
        return false;
    }

    return !!route.name?.toString().startsWith(props.routeGroup);
});

const isAuthorizedToView = computed(() => {
    if (parentRoute && !checkUserIsAuthorizedToVisitRoute(parentRoute)) {
        return false;
    }

    // If no route group is specified, the route meta can't be found and authorization checks
    // should be set directly on this component.
    if (!props.routeGroup) {
        return true;
    }

    for (const childRoute of childRoutes) {
        if (childRoute.path === '/caregroups/:uuid' || checkUserIsAuthorizedToVisitRoute(childRoute)) {
            return true;
        }
    }

    return false;
});

watch(route, () => {
    isOpen.value = !!route.name?.toString().startsWith(props.routeGroup ?? '');
});

onMounted(() => {
    isOpen.value = !!route.name?.toString().startsWith(props.routeGroup ?? '');
});
</script>
