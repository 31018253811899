import useAuth, { type AuthUser } from '~/stores/Auth';
import { UserStatus } from '~/typings/enums';
import useToast from '../hooks/toast';
import Log from '../logging/Logger';
import { getRouter } from '../router/RouterPipeline';

function isUpdated(old: AuthUser, fresh: AuthUser, key: keyof AuthUser): boolean {
    return old[key] !== fresh[key];
}

function getUpdatedAttributes(old: AuthUser, fresh: AuthUser): Array<keyof AuthUser> {
    // eslint-disable-next-line unicorn/no-array-reduce
    return Object.keys(fresh).filter(key => {
        const $key = key as keyof AuthUser;

        return isUpdated(old, fresh, $key);
    }) as Array<keyof AuthUser>;
}

function onEmailChange(): void {
    const router = getRouter();
    router.push({ name: 'auth.verify-email' });
    useToast({
        type: 'info',
        context: 'toasts.email_must_be_verified',
        timeout: false,
    });
}

function onStatusChange(_oldValue: UserStatus, newValue: UserStatus): void {
    const router = getRouter();

    if (newValue === UserStatus.Approved) {
        router.push({ name: 'dashboard' });
        useToast({
            type: 'success',
            context: 'toasts.approved',
            timeout: false,
        });

        return;
    }

    router.push({ name: 'status' });
}

const handlers: Partial<Record<keyof AuthUser, ((oldValue: any, newValue: any) => void)>> = {
    email: onEmailChange,
    user_status: onStatusChange,
};

export default async function updatedAuthUserHandler(): Promise<void> {
    const auth = useAuth();
    const old: AuthUser = JSON.parse(JSON.stringify(auth.user));

    auth.validate()
        .then(() => {
            const fresh = auth.user;

            if (!fresh) {
                return;
            }

            getUpdatedAttributes(old, fresh).forEach(attribute => {
                const handler = handlers[attribute];
                handler?.(old[attribute], fresh[attribute]);

                if (handler) {
                    Log.debug(`[Updated auth user event handler]: ${attribute} changed, called ${handler.name} handler.`);
                }
            });
        });
}
