import { useSessionStorage } from '@vueuse/core';
import useMiddleware from '~/lib/hooks/middleware';
import useAuth from '~/stores/Auth';

const clearTherapySessionStorage = useMiddleware(async (to, from, next) => {
    const { isGuest } = useAuth();

    if (isGuest && to.path.includes('therapy/request/success')) {
        // Clear local session storage;
        const therapySessionStorage = [
            'therapy-patient',
            'therapy-delivery-address',
            'therapy-pharmacy',
            'therapy-health-insurance',
            'therapy-specialist'];

        therapySessionStorage.forEach(key => {
            useSessionStorage(key, {}).value = {};
        });
    }

    return next(to, from);
});

export default clearTherapySessionStorage;
