<template>
    <div
        role="alert"
        data-type="validation-error-message"
        class="px-2 lg:px-1 rounded bg-white border
            select-none animate-shake z-[3] text-xs font-semibold lg:font-normal
            text-wgm-red-primary border-wgm-red-primary"
    >
        <span>{{ message && te(message) ? translate(message) : message }}</span>
    </div>
</template>

<script setup lang="ts">
interface Props {
    message: string | null | undefined;
}

defineProps<Props>();

const { t: translate, te } = useI18n();
</script>
