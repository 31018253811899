import type { DatetimeFormat } from '~/typings/types';

export default abstract class BaseDatetime {
    public static format: (date: string, format: DatetimeFormat, reactive?: boolean) => string;
    public static parse: (date: string) => BaseDatetime;
    public static now: () => BaseDatetime;
    public static isPast: (date: string) => boolean;
    public static isFuture: (date: string) => boolean;
    public static isBefore: (dateX: string, dateY: string) => boolean;
    public static isAfter: (dateX: string, dateY: string) => boolean;
    public static isBetween: (date: string, dateX: string, dateY: string) => boolean;
    public static isToday: (date: string) => boolean;
    public static isSame: (dateX: string, dateY: string, type: 'day' | 'week' | 'year') => boolean;
    public abstract format(format: DatetimeFormat, reactive?: boolean): string;
    public abstract unix(): number;
    public abstract addHour(hours: number): this;
    public abstract addDay(days: number): this;
    public abstract addWeek(weeks: number): this;
    public abstract addMonth(months: number): this;
    public abstract addYear(years: number): this;
    public abstract subHour(hours: number): this;
    public abstract subDay(days: number): this;
    public abstract subWeek(weeks: number): this;
    public abstract subMonth(months: number): this;
    public abstract subYear(years: number): this;
    public abstract setMillisecond(millisecond: number): this;
    public abstract setSecond(second: number): this;
    public abstract setMinute(minute: number): this;
    public abstract setHour(hour: number): this;
    public abstract startOfDay(): this;
    public abstract endOfDay(): this;
    public abstract toHumanReadable(reactive?: boolean): string;
    public abstract toString(): string;
}
