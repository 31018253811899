<template>
    <div
        class="z-50 fixed sm:absolute top-20 left-4 sm:left-[unset] sm:top-9 sm:right-0 lg:right-0 w-[calc(100%-2rem)]
            sm:w-[26rem] bg-white border border-wgm-gray-400 rounded-md shadow-lg text-sm"
    >
        <div v-if="notifications.length > 0">
            <div class="sm:flex items-center justify-between gap-2 py-2 px-4">
                <h4 class="font-semibold text-base">
                    {{ $t('dashboard.notifications.new_notifications') }}
                </h4>
                <button
                    type="button"
                    class="ml-auto flex items-end gap-1 hover:underline text-wgm-blue-800"
                    @click="() => notification.markAllNotificationsAsRead()"
                >
                    <IconDoubleCheckmark
                        class="fill-current"
                        width="18"
                        height="18"
                    />
                    <span>{{ $t('dashboard.notifications.mark_all_as_read') }}</span>
                </button>
            </div>
            <ul class="grid">
                <NotificationDropdownItem
                    v-for="$notification, index in notifications"
                    :key="$notification.uuid"
                    :notification="$notification"
                    :index="index"
                    class="px-2 overflow-x-hidden"
                    @click="$emit('show', $notification)"
                />
            </ul>
            <div
                v-if="$route.name?.toString() !== 'dashboard'"
                class="py-2 px-4 border-t border-t-wgm-gray-400 mt-2"
            >
                <RouterLink
                    :to="{name: 'dashboard'}"
                    class="hover:underline text-wgm-blue-800"
                    @keydown.prevent.tab="$emit('close');"
                >
                    {{ $t('dashboard.notifications.view_all') }}
                </RouterLink>
            </div>
            <div
                v-else
                class="pb-2"
            ></div>
        </div>
        <div v-else>
            <div class="px-4 py-2">
                {{ $t('dashboard.notifications.no_unread') }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import useNotification from '~/stores/Notification';
import type { Notification } from '~/stores/Notification';

interface Props {
    notifications: Notification[];
}

interface Events {
    (event: 'show', value: Notification): void;
    (event: 'close'): void;
}

defineProps<Props>();
defineEmits<Events>();

const notification = useNotification();
</script>
