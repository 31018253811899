<template>
    <div
        id="backdrop"
        ref="backdrop"
        class="fixed inset-0 z-[49] select-none transition-opacity duration-150"
        :class="{ 'bg-black opacity-0': state.darkenedBackdrop }"
        @click.stop="handleClick"
    ></div>
</template>

<script setup lang="ts">
import useState from '~/stores/State';

interface Events {
    (event: 'click', value: Event): void;
}

const emit = defineEmits<Events>();

const state = useState();
const backdrop = ref<HTMLDivElement>();

const handleClick = (event: Event) => {
    state.showBackdrop = false;
    emit('click', event);
};

onMounted(() => {
    if (state.darkenedBackdrop) {
        setTimeout(() => {
            backdrop.value?.classList.remove('opacity-0');
            backdrop.value?.classList.add('opacity-20');
        }, 10);
    }
});
</script>
