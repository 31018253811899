import deepmerge from 'deepmerge';
import {
    defineStore,
    type DefineStoreOptions,
    type PiniaCustomProperties,
    type StoreDefinition,
    type _StoreWithGetters,
    type _StoreWithState,
} from 'pinia';
import {
    type PrimaryKey,
    type Model,
    type ModelPayload,
    type ModelState,
    type ModelGetters,
    type ModelActions,
    type Parameters,
    type Meta,
    $primaryKey,
} from '~/typings/model';
import useHttp from '~/lib/http';
import type { AnyObject } from '~/typings/interfaces';
import type { Uuid } from '~/typings/types';

/* eslint-disable @typescript-eslint/ban-types */

export function defineModel<
    M extends Model,
    CM extends Model = M,
    C extends AnyObject = ModelPayload<M>,
    U extends Partial<ModelPayload<M>> = Partial<ModelPayload<M>>,
>(endpoint?: string, primaryKeyField: string = $primaryKey): [string | undefined, M, CM, C, U, PrimaryKey] {
    return [endpoint, {} as M, {} as CM, {} as C, {} as U, primaryKeyField];
}

export function modelArrayToMap<M extends Model>(models: M[], primaryKeyField: keyof M = 'uuid'): Map<PrimaryKey, M> {
    const preparedForMap: Array<[PrimaryKey, M]> = models.map(model => {
        return [model[primaryKeyField], model];
    });

    const mappedModels: Map<PrimaryKey, M> = new Map(preparedForMap);

    return mappedModels;
}

export default function createModel<
    M extends Model, // Model
    CM extends Model = M, // computed Model
    Id extends string = string, // Pinia Store id
    S extends Partial<ModelState<M>> = {}, // Pinia Store State Extended
    G extends Partial<ModelGetters<S & ModelState<M>, CM>> = {}, // Pinia Store Get Extended
    A extends Partial<ModelActions<M, CM, C, U>> = {}, // Pinia Store Actions Extended
    C extends AnyObject = ModelPayload<M>, // Create payload
    U extends Partial<ModelPayload<M>> = Partial<ModelPayload<M>>, // Update Payload,
>(
    storeId: Id,
    modelDefinition?: [string | undefined, M, CM, C, U, PrimaryKey],
    storeOptions: Omit<
        DefineStoreOptions<Id, S & ModelState<M>, G, A>, 'id'
    > = {},
): StoreDefinition<Id, S & ModelState<M>, G & ModelGetters<ModelState<M>, CM>, A & ModelActions<M, CM, C, U>> {
    const endpoint = modelDefinition?.[0] !== undefined ? modelDefinition[0] : storeId;
    const primaryKeyField: keyof M = modelDefinition?.[5] ?? $primaryKey;

    const modelState: ModelState<M> = {
        models: new Map<PrimaryKey, M>(),
        meta: <Partial<Meta>>{},
    };

    const modelGetters: ModelGetters<S, CM> = {
        collection(state) {
            if (!state.models) {
                return new Map<PrimaryKey, CM>();
            }

            return modelArrayToMap([...state.models.values()] as M[], primaryKeyField) as unknown as Map<PrimaryKey, CM>;
        },
    };

    const modelActions: ModelActions<M, CM, C, U>
        & ThisType<
            A & ModelActions<M, CM, C, U> & ModelState<M>
            & _StoreWithState<Id, ModelState<M>, ModelGetters<ModelState<M>, CM>, A>
            & _StoreWithGetters<ModelGetters<ModelState<M>, CM>
            >
            & PiniaCustomProperties
        > = {
        async fetch<K extends keyof CM>(primaryKey: PrimaryKey, params: Parameters<K> = {}, stringifyEmptyParameters = true) {
            const queryString = useHttp().stringifyParameters(params, stringifyEmptyParameters);
            const model = await useHttp().get<M>(`${endpoint}/${primaryKey}${queryString}`);

            const current = this.models.get(model[primaryKeyField]);
            const fresh = current ? Object.assign(current, model) : model;
            this.models.set(model[primaryKeyField], fresh);

            return this.collection.get(primaryKey) as unknown as Promise<CM>;
        },
        async fetchAll<K extends keyof CM>(params: Parameters<K> = {}, stringifyEmptyParameters = true) {
            const queryString = useHttp().stringifyParameters(params, stringifyEmptyParameters);
            const { data, meta } = await useHttp().get<{ data: M[]; meta: Meta }>(`/${endpoint}${queryString}`, undefined, { paginate: true });

            data.forEach(model => {
                const current = this.models.get(model[primaryKeyField]);
                const fresh = current ? Object.assign(current, model) : model;
                this.models.set(model[primaryKeyField], fresh);
            });

            this.meta = meta;

            return this.collection;
        },
        findAndFetch<K extends keyof CM>(primaryKey: PrimaryKey, params: Parameters<K> = {}, stringifyEmptyParameters = true) {
            const queryString = useHttp().stringifyParameters(params, stringifyEmptyParameters);

            const fetcher = async (id: Uuid = primaryKey) => {
                this.models.delete(id);

                return useHttp().get<M>(`${endpoint}/${id}${queryString}`)
                    .then(model => {
                        const current = this.models.get(model[primaryKeyField]);
                        const fresh = current ? Object.assign(current, model) : model;
                        this.models.set(model[primaryKeyField], fresh);
                    });
            };

            fetcher();

            return computed(() => {
                const model = this.collection.get(primaryKey);

                if (!model) {
                    return null;
                }

                return Object.assign(model, {
                    fresh: fetcher,
                });
            });
        },
        lazyFetch<K extends keyof CM>(primaryKey: PrimaryKey, params: Parameters<K> = {}, stringifyEmptyParameters = true) {
            const queryString = useHttp().stringifyParameters(params, stringifyEmptyParameters);

            const fetcher = async (id: Uuid = primaryKey) => {
                return useHttp().get<M>(`${endpoint}/${id}${queryString}`)
                    .then(model => {
                        this.models.set(id, model);
                    });
            };

            const reactiveModel = computed(() => {
                return this.collection.get(primaryKey) ?? null;
            });

            return [reactiveModel, fetcher];
        },
        lazyFetchMany<K extends keyof CM>(primaryKey: PrimaryKey[], params: Parameters<K> = {}, stringifyEmptyParameters = true) {
            const ids = ref<PrimaryKey[]>([]);

            const fetcher = async (id: PrimaryKey[] = primaryKey) => {
                const preparedParameters: Parameters<K> = { ...params, filter: [`[uuid]=${id.join(',')}`] };
                const queryString = useHttp().stringifyParameters(preparedParameters, stringifyEmptyParameters);

                return useHttp().get<M[]>(`${endpoint}${queryString}`)
                    .then(models => {
                        ids.value = id;
                        models.forEach(model => this.models.set(model.uuid, model));
                    });
            };

            const reactiveModels = computed(() => {
                // eslint-disable-next-line unicorn/no-array-reduce
                const models = ids.value.reduce((stack: CM[], current) => {
                    const model = this.collection.get(current);

                    if (model) {
                        stack.push(model);
                    }

                    return stack;
                }, []);

                return models;
            });

            return [reactiveModels, fetcher];
        },
        find(primaryKey: PrimaryKey) {
            return this.collection.get(primaryKey) ?? null;
        },
        findRaw(primaryKey: PrimaryKey) {
            return this.models.get(primaryKey) ?? null;
        },
        async findOrFetch<K extends keyof CM>(primaryKey: PrimaryKey, params: Parameters<K> = {}, stringifyEmptyParameters = true) {
            if (this.collection.has(primaryKey)) {
                return this.collection.get(primaryKey) as CM;
            }

            return this.fetch(primaryKey, params, stringifyEmptyParameters);
        },
        async create(payload: C) {
            return useHttp().post<M>(endpoint, payload)
                .then(model => {
                    this.models.set(model[primaryKeyField], model);

                    return this.collection.get(model[primaryKeyField]) as unknown as CM;
                });
        },
        async update(primaryKey: PrimaryKey, payload: U, preSync = true) {
            let original: M | null = null;

            if (preSync) {
                const model = this.models.get(primaryKey);
                original = model ? { ...model } : null;

                if (original) {
                    const updatedModel = deepmerge(original, payload) as unknown as M;
                    this.models.set(primaryKey, updatedModel);
                }
            }

            return useHttp().patch<M>(`${endpoint}/${primaryKey}`, payload)
                .then(data => {
                    this.models.set(primaryKey, data);

                    return this.collection.get(primaryKey) as unknown as CM;
                })
                .catch(error => {
                    if (preSync && original) {
                        this.models.set(primaryKey, original);
                    }

                    throw error;
                });
        },
        async destroy(primaryKey: PrimaryKey, preSync = true): Promise<void> {
            let original: M | null = null;

            if (preSync) {
                const model = this.models.get(primaryKey);
                original = model ? { ...model } : null;

                this.models.delete(primaryKey);
            }

            return useHttp().delete(`${endpoint}/${primaryKey}`)
                .catch(error => {
                    if (preSync && original) {
                        this.models.set(primaryKey, original);
                    }

                    throw error;
                });
        },
    };

    const storeState = Object.assign(modelState, storeOptions.state?.() ?? {} as S);
    const storeGetters = Object.assign(modelGetters, storeOptions.getters ?? {} as G);
    const storeActions = Object.assign(modelActions, storeOptions.actions ?? {} as G) as A & ModelActions<M, CM, C, U>;

    const modelStoreOptions: Omit<DefineStoreOptions<
        Id, S & ModelState<M>, G & ModelGetters<S, CM>, A & ModelActions<M, CM, C, U>>, 'id'
    > = {
        state: () => storeState,
        getters: storeGetters,
        actions: storeActions,
        hydrate: storeOptions.hydrate,
    };

    return defineStore(storeId, modelStoreOptions);
}
