import { isString } from '~/helpers';
import type { ValidationRuleFunction } from '~/validation';

export const bigCheck = (value: unknown): boolean => {
    if (!value) {
        return true;
    }

    if (!isString(value)) {
        return false;
    }

    if (value.trim().length < 9 || value.trim().length > 11) {
        return false;
    }

    return /^\d*$/.test(value);
};

const bigRules: Record<string, ValidationRuleFunction> = {
    big: bigCheck,
};

export default bigRules;
