<template>
    <div
        class="relative"
        :class="[wrapperClasses, {'items-center grid grid-cols-[1fr_2fr]': horizontal}]"
    >
        <Label
            v-if="label"
            :label="label"
            :for="id"
            :required="isRequired"
            :class="{'pr-8 text-right': horizontal }"
        />

        <select
            :id="id"
            v-model="_value"
            :name="name || id"
            class="block w-full bg-white border border-wgm-gray-300 rounded-md text-lg p-2
                    transition-shadow outline-none focus:shadow-md leading-6"
            v-bind="$attrs"
            @blur="onBlur"
        >
            <slot></slot>
        </select>
        <InputErrorMessage
            v-if="validationMessage"
            class="absolute -bottom-4 -right-1"
            :message="validationMessage"
        />
    </div>
</template>

<script setup lang="ts">
import { useField, type RuleExpression } from 'vee-validate';
import useValidation from '~/composables/useValidation';

type Props = {
    id: string;
    name?: string;
    noSpacing?: boolean;
    value?: string;
    modelValue?: string|null;
    required?: boolean;
    label?: string;
    validationLabel?: string;
    wrapperClasses?: string;
    horizontal?: boolean;
    rules?: RuleExpression<string>;
    validateOnUpdate?: boolean;
    validateOnBlur?: boolean;
}

type Events = {
    (event: 'update:modelValue', value: string): void;
    (event: 'change', value: string): void;
}

const props = withDefaults(defineProps<Props>(), {
    name: undefined,
    noSpacing: false,
    modelValue: undefined,
    label: undefined,
    value: undefined,
    wrapperClasses: undefined,
    horizontal: false,
    rules: undefined,
    required: false,
    validationLabel: undefined,
    validateOnUpdate: false,
    validateOnBlur: true,
});

const emit = defineEmits<Events>();

const {
    errorMessage, setValue, setTouched, validate, resetField,
} = useField(props.name ?? props.id, props.rules, {
    validateOnValueUpdate: props.validateOnUpdate,
    label: props.validationLabel ?? (props.label ? `"${props.label}"` : undefined),
    type: 'default',
    initialValue: props.modelValue ?? props.value ?? '',
});

const { isRequired, validationMessage, clearServerValidationError } = useValidation(props, { errorMessage });

const _value = computed({
    get: () => props.modelValue ?? props.value ?? '',
    set: value => {
        clearServerValidationError();
        setValue(value, props.validateOnUpdate);
        emit('update:modelValue', value);
        emit('change', value);
    },
});

function onBlur() {
    if (!props.validateOnBlur) {
        return;
    }

    if (!isRequired.value && !_value.value) {
        resetField();
    } else {
        setTouched(true);
        validate();
    }
}
</script>
