<template>
    <header class="py-8 pt-[calc(2rem+var(--safe-area-inset-top,0))] border-b border-b-wgm-gray-300">
        <div class="max-w-5xl mx-auto px-4 flex justify-between items-center">
            <RouterLink
                :to="{name: 'dashboard'}"
                class="-my-3"
            >
                <img
                    src="../../assets/logo.png"
                    alt=""
                    class="h-16"
                >
            </RouterLink>
            <div class="hidden //flex// items-center gap-x-4">
                <nav aria-role="Header navigation links">
                    <ul class="flex items-center text-wgm-gray-800">
                        <li class="px-4 font-semibold">
                            <RouterLink
                                :to="{name: 'dashboard'}"
                                class="hover:text-black"
                            >
                                {{ $t('general.header.navigation.welcome') }}
                            </RouterLink>
                        </li>
                    </ul>
                </nav>
                <div class="pl-8 py-1 border-l border-l-wgm-gray-300">
                    <HeaderLanguageToggle />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">

</script>
