<template>
    <div class="m-4 md:m-6 fixed top-5 right-5 grid gap-4 z-10">
        <TransitionGroup
            tag="ul"
            class="grid gap-1"
            enter-from-class="opacity-0 translate-x-5"
            enter-active-class="transition-all duration-300"
            leave-to-class="opacity-0 translate-x-5"
            leave-active-class="transition-all duration-500 ease-in-out"
        >
            <ToastItem
                v-for="toast in state.toasts.values()"
                :key="toast.toast_id"
                :toast="toast"
            />
        </TransitionGroup>
    </div>
</template>

<script setup lang="ts">
import useState from '~/stores/State';

const state = useState();
</script>
