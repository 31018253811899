import createModel, { defineModel } from '~/lib/model';
import useLoading from '~/lib/hooks/loading';
import useHttp from '~/lib/http';

import type { TrackingSystemLocationStatus, TrackingSystemLocationType } from '~/typings/enums';
import type { Model, ModelDefinition } from '~/typings/model';
import type { Label } from '~/models/Label';
import type { Uuid } from '~/typings/types';

export interface Location extends Model {
    id: number;
    designation: string;
    description: string;
    type: TrackingSystemLocationType;
    status: TrackingSystemLocationStatus;
    inventory_count?: number;
    labels?: Label[];
    is_default_delivery: boolean;
    barcode_hash: string;
}

const locationModel = createModel('location', defineModel<Location>('tracking-system/location'), {
    actions: {
        async delete(uuid: Uuid) {
            return useLoading(async () => {
                return useHttp().delete(`tracking-system/location/${uuid}`)
                    .then(() => {
                        this.models.delete(uuid);
                    });
            })();
        },
    },
});

const useLocation = (): ModelDefinition<typeof locationModel> => locationModel();

export default useLocation;
