import { Permission } from '~/typings/enums';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'locations',
        name: 'tracking.locations',
        component: async () => import('~/views/RouteViewWrapper.vue'),
        redirect: {
            name: 'locations.overview',
        },
        children: [
            {
                path: '',
                name: 'module.tracking-system.locations',
                component: async () => import('../views/Location/Overview.vue'),
            },
            {
                path: ':uuid',
                component: async () => import('../views/Location/RouterView.vue'),
                children: [
                    {
                        path: '',
                        name: 'module.tracking-system.location',
                        component: async () => import('../views/Location/Detail.vue'),
                        meta: {
                            title: 'Locatie details',
                        },
                    },
                    {
                        path: 'barcode/:barcode',
                        name: 'module.tracking-system.location.barcode',
                        component: async () => import('../views/Location/Barcode.vue'),
                    },
                    {
                        path: 'print',
                        name: 'module.tracking-system.location.print',
                        component: async () => import('../views/Location/Print.vue'),
                    },
                ],
            },
            {
                path: 'settings',
                name: 'module.tracking-system.locations.settings',
                component: async () => import('../views/Location/Settings.vue'),
                meta: {
                    permission: [
                        Permission.Module_TrackingSystem_Locations_Edit,
                    ],
                },
            },
        ],
    },
];

export default routes;
